import {
  ActionReducerMapBuilder,
  createAsyncThunk,
  createSlice,
  PayloadAction,
} from '@reduxjs/toolkit'
import CollectionRepository from '@/app/repositories/CollectionRepository'
import { show } from '@/features/notify/notify-slice'

const collectionRepo = new CollectionRepository()

export const collectionListInput = createAsyncThunk(
  'manager/collections/list/input',
  async (params: any, { rejectWithValue, dispatch, getState }) => {
    let state: any = getState()
    let newParams = { ...params }
    if (state.managerStore.store) {
      newParams.store_id = state.managerStore.store.ID
    }

    let { status, response } = await collectionRepo.list(newParams)

    if (!status) {
      dispatch(
        show({
          content: response,
          severity: 'error',
        })
      )
      return rejectWithValue(response)
    }

    return response
  }
)

export const collectionList = createAsyncThunk(
  'manager/collections/list',
  async (params: any, { rejectWithValue, dispatch, getState }) => {
    let state: any = getState()
    let newParams = { ...params }
    if (state.managerStore.store) {
      newParams.store_id = state.managerStore.store.ID
    }

    let { status, response } = await collectionRepo.list(newParams)

    if (!status) {
      dispatch(
        show({
          content: response,
          severity: 'error',
        })
      )
      return rejectWithValue(response)
    }

    return response
  }
)

export const collectionCreate = createAsyncThunk(
  'manager/collections/create',
  async (data: any, { rejectWithValue, dispatch }) => {
    let { status, response } = await collectionRepo.collectionCreate(data)

    if (!status) {
      dispatch(
        show({
          content: response,
          severity: 'error',
        })
      )
      return rejectWithValue(response)
    }

    dispatch(
      show({
        content: 'Collection create success',
        severity: 'success',
      })
    )
    return response
  }
)

export const collectionDelete = createAsyncThunk(
  'manager/collections/delete',
  async ({ id, params }: { id: number; params: any }, { rejectWithValue, dispatch, getState }) => {
    let state: any = getState()
    let newParams = { ...params }
    if (state.managerStore.store) {
      newParams.store_id = state.managerStore.store.ID
    }
    let { status, response } = await collectionRepo.delete(id, newParams)

    if (!status) {
      dispatch(
        show({
          content: response,
          severity: 'error',
        })
      )
      return rejectWithValue(response)
    }

    dispatch(
      show({
        content: 'Collection delete success',
        severity: 'success',
      })
    )

    return response
  }
)

export const collectionDetail = createAsyncThunk(
  'manager/collections/detail',
  async ({ id, params }: { id: string; params: any }, { rejectWithValue, dispatch, getState }) => {
    let state: any = getState()
    let newParams = { ...params }
    if (state.managerStore.store) {
      newParams.store_id = state.managerStore.store.ID
    }
    let { status, response } = await collectionRepo.detail(id, newParams)

    if (!status) {
      dispatch(
        show({
          content: response,
          severity: 'error',
        })
      )
      return rejectWithValue(response)
    }

    return response
  }
)

export const collectionUpdate = createAsyncThunk(
  'manager/collections/update',
  async ({ id, data }: { id: string; data: any }, { rejectWithValue, dispatch }) => {
    let { status, response } = await collectionRepo.collectionUpdate(id, data)

    if (!status) {
      dispatch(
        show({
          content: response,
          severity: 'error',
        })
      )
      return rejectWithValue(response)
    }

    dispatch(
      show({
        content: 'Collection update success',
        severity: 'success',
      })
    )

    return response
  }
)

interface ManagerCollectionState {
  isLoading: boolean
  collectionsInputFilters: object
  collectionsInput: Array<object>
  collectionsInputPagination: any
  collectionsFilters: object
  collections: Array<object>
  collectionsPagination: any
}

const initialState: ManagerCollectionState = {
  isLoading: false,
  collectionsInputFilters: {
    limit: 20,
    page: 1,
    sort_by: 'id:desc',
    store_id: 0,
  },
  collectionsInput: [],
  collectionsInputPagination: {
    limit: 20,
    page: 1,
    total_pages: 1,
    total_rows: 4,
  },
  collectionsFilters: {
    limit: 20,
    page: 1,
    sort_by: 'id:desc',
    store_id: 0,
  },
  collections: [],
  collectionsPagination: {
    limit: 20,
    page: 1,
    total_pages: 1,
    total_rows: 4,
  },
}

const managerCollectionSlice = createSlice({
  name: 'managerCollection',
  initialState,
  reducers: {
    setCollectionsFilters(state, action: PayloadAction<any>) {
      state.collectionsFilters = action.payload
    },
  },
  extraReducers: (builder: ActionReducerMapBuilder<ManagerCollectionState>) => {
    builder.addCase(collectionListInput.pending, (state: ManagerCollectionState) => {
      state.isLoading = true
    })

    builder.addCase(
      collectionListInput.fulfilled,
      (state: ManagerCollectionState, action: PayloadAction<any>) => {
        state.collectionsInput = action.payload.data
        state.collectionsInputPagination = action.payload.pagination
        state.isLoading = false
      }
    )

    builder.addCase(collectionListInput.rejected, (state: ManagerCollectionState) => {
      state.isLoading = false
    })

    builder.addCase(collectionList.pending, (state: ManagerCollectionState) => {
      state.isLoading = true
    })

    builder.addCase(
      collectionList.fulfilled,
      (state: ManagerCollectionState, action: PayloadAction<any>) => {
        state.collections = action.payload.data
        state.collectionsPagination = action.payload.pagination
        state.isLoading = false
      }
    )

    builder.addCase(collectionList.rejected, (state: ManagerCollectionState) => {
      state.isLoading = false
    })
  },
})

export const { setCollectionsFilters } = managerCollectionSlice.actions
export default managerCollectionSlice.reducer
