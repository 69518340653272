import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import ProductRepository from '../../../app/repositories/site/ProductRepository'
import { show } from '../../notify/notify-slice'

const productRepo = new ProductRepository()

// export const productFeatured = createAsyncThunk(
//   'product',
//   async (params: any, { rejectWithValue, dispatch, getState }) => {
//     let state: any = getState()
//     params.store_id = params.store_id ? params.store_id : state.store.store.ID

//     let { status, response } = await productRepo.productFeatured(params)

//     if (!status) {
//       dispatch(
//         show({
//           content: response,
//           severity: 'error',
//         })
//       )
//       return rejectWithValue(response)
//     }

//     return response
//   }
// )

export const productFeatured = createAsyncThunk(
    'product/info',
    async (params: any, { rejectWithValue, dispatch, getState }) => {
      let state: any = getState()
      params.token = params.token ? params.token : state.user?.tokens.access
  
      let { status, response } = await productRepo.productFeatured(params)
  
      if (!status) {
        dispatch(
          show({
            content: response,
            severity: 'error',
          })
        )
        return rejectWithValue(response)
      }
  
      return response
    }
  )

interface AppState {
  isLoading: boolean
  product: any
  productFeatureds: Array<object>
  productsPagination: any
  isCreated: boolean
}

const initialState: AppState = {
  isLoading: false,
  product: null,
  productFeatureds: [],
  productsPagination: {
    limit: 20,
    page: 1,
    total_pages: 1,
    total_rows: 4,
  },
  isCreated: false,
}

const appSlice = createSlice({
  name: 'app',
  initialState,
  reducers: {
  },
  extraReducers: (builder) => {
    builder.addCase(productFeatured.pending, (state) => {})

    builder.addCase(productFeatured.fulfilled, (state, action) => {
      state.productFeatureds = action.payload.data
      state.productsPagination = action.payload.pagination
    })

    builder.addCase(productFeatured.rejected, (state, action) => {})
  },
})

export const { } = appSlice.actions
export default appSlice.reducer
