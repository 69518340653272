import {
  ActionReducerMapBuilder,
  createAsyncThunk,
  createSlice,
  PayloadAction,
} from '@reduxjs/toolkit'
import MenuRepository from '@/app/repositories/MenuRepository'
import { show } from '@/features/notify/notify-slice'

const menuRepo = new MenuRepository()

export const menuListInput = createAsyncThunk(
  'menu/list/input',
  async (params: any, { rejectWithValue, dispatch, getState }) => {
    let state: any = getState()
    let newParams = { ...params }
    if (state.managerStore.store) {
      newParams.store_id = state.managerStore.store.ID
    }

    let { status, response } = await menuRepo.list(newParams)

    if (!status) {
      dispatch(
        show({
          content: response,
          severity: 'error',
        })
      )
      return rejectWithValue(response)
    }

    return response
  }
)

interface ManagerMenuState {
  isLoading: boolean
  menusInputFilters: object
  menusInput: Array<object>
  menusInputPagination: any
}

const initialState: ManagerMenuState = {
  isLoading: false,
  menusInputFilters: {
    limit: 20,
    page: 1,
    sort_by: 'id:desc',
    store_id: 0,
  },
  menusInput: [],
  menusInputPagination: {
    limit: 20,
    page: 1,
    total_pages: 1,
    total_rows: 4,
  },
}

const managerMenuSlice = createSlice({
  name: 'managerMenu',
  initialState,
  reducers: {},
  extraReducers: (builder: ActionReducerMapBuilder<ManagerMenuState>) => {
    builder.addCase(menuListInput.pending, (state: ManagerMenuState) => {
      state.isLoading = true
    })

    builder.addCase(
      menuListInput.fulfilled,
      (state: ManagerMenuState, action: PayloadAction<any>) => {
        state.menusInput = action.payload.data
        state.menusInputPagination = action.payload.pagination
        state.isLoading = false
      }
    )

    builder.addCase(menuListInput.rejected, (state: ManagerMenuState) => {
      state.isLoading = false
    })
  },
})

export const {} = managerMenuSlice.actions
export default managerMenuSlice.reducer
