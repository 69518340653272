import BaseRepository from './BaseRepository'

class StaffRepository extends BaseRepository {
  url() {
    return '/'
  }

  handlerHttpError(e: any) {
    if (e.response && e.response.data) {
      let errorMsg = e.response.data.msg
      return this.error(errorMsg)
    } else {
      throw e
    }
  }

  async sessionCreate(data: any, query = {}, headers = {}) {
    headers = {
      Authorization: 'Bearer ' + data.token,
      'Content-Type': 'multipart/form-data',
    }
    try {
      // let response = await this.httpClient.post(this.url() + 'product/create', formData, {
      //   params: query,
      //   headers: headers,
      // })
      return this.success({status: true})
    } catch (e) {
      return this.handlerHttpError(e)
    }
  }

  async sessionConfirm(query: any, headers = {}) {
    try {
      // let response = await this.httpClient.get(this.url() + 'products', {
      //   params: query,
      //   headers: headers,
      // })
      return this.success({status: true})
    } catch (e) {
      return this.handlerHttpError(e)
    }
  }

}

export default StaffRepository
