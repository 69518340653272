import BaseRepository from './BaseRepository'
class StoreRepository extends BaseRepository {
  url() {
    return '/'
  }
  handlerHttpError(e: any) {
    if (e.response && e.response.data) {
      let errorMsg = e.response.data.msg
      return this.error(errorMsg)
    } else {
      throw e
    }
  }

  async storeBySubdomain(data: any, query = {}, headers = {}) {
    try {
      let subdomain = window.location.href.replace(/^https?:\/\//, '').split('.')[0]
      if (window.location.pathname.indexOf('store') > -1) {
        let paths = window.location.pathname.split('/')
        if (paths.length > 2) {
          subdomain = paths[2]
        }
      }

      let response = await this.httpClient.get(this.url() + `stores/subdomain/${subdomain}`, {
        params: query,
        headers: headers,
      })
      return this.success(response.data)
    } catch (e) {
      return this.handlerHttpError(e)
    }
  }

  async storeCreate(data: any, query = {}, headers = {}) {
    try {
      let response = await this.httpClient.post(this.url() + 'stores', data, {
        params: query,
        headers: headers,
      })
      return this.success(response.data)
    } catch (e) {
      return this.handlerHttpError(e)
    }
  }

  async storeList(query: any, headers = {}) {
    try {
      let response = await this.httpClient.get(this.url() + 'stores', {
        params: query,
        headers: headers,
      })
      return this.success(response.data)
    } catch (e) {
      return this.handlerHttpError(e)
    }
  }

  async storeUpdate(id: number, data: any, query = {}, headers = {}) {
    try {
      let response = await this.httpClient.put(this.url() + `stores/${id}`, data, {
        params: query,
        headers: headers,
      })
      return this.success(response.data)
    } catch (e) {
      return this.handlerHttpError(e)
    }
  }
}

export default StoreRepository
