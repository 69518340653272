import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import ThemeRepository from '@/app/repositories/ThemeRepository'
import { show } from '@/features/notify/notify-slice'

const themeRepo = new ThemeRepository()

export const themeCreate = createAsyncThunk(
  'themes/create',
  async (data: any, { rejectWithValue, dispatch }) => {
    let { status, response } = await themeRepo.themeCreate(data)

    if (!status) {
      dispatch(
        show({
          content: response,
          severity: 'error',
        })
      )
      return rejectWithValue(response)
    }

    dispatch(
      show({
        content: 'Theme create success',
        severity: 'success',
      })
    )
    return response
  }
)

export const themeList = createAsyncThunk(
  'themes/list',
  async (params: any, { rejectWithValue, dispatch, getState }) => {
    let state: any = getState()
    let newParams = { ...params }
    if (state.managerStore.store) {
      newParams.store_id = state.managerStore.store.ID
    }

    let { status, response } = await themeRepo.list(newParams)

    if (!status) {
      dispatch(
        show({
          content: response,
          severity: 'error',
        })
      )
      return rejectWithValue(response)
    }

    return response
  }
)

export const themeDelete = createAsyncThunk(
  'themes/delete',
  async ({ id, params }: { id: number; params: any }, { rejectWithValue, dispatch }) => {
    let { status, response } = await themeRepo.delete(id, params)

    if (!status) {
      dispatch(
        show({
          content: response,
          severity: 'error',
        })
      )
      return rejectWithValue(response)
    }

    dispatch(
      show({
        content: 'Theme delete success',
        severity: 'success',
      })
    )

    return response
  }
)

export const themeDetail = createAsyncThunk(
  'themes/detail',
  async ({ id, params }: { id: string; params: any }, { rejectWithValue, dispatch }) => {
    let { status, response } = await themeRepo.detail(id, params)

    if (!status) {
      dispatch(
        show({
          content: response,
          severity: 'error',
        })
      )
      return rejectWithValue(response)
    }

    return response
  }
)

export const themeUpdate = createAsyncThunk(
  'themes/update',
  async ({ id, data }: { id: number; data: any }, { rejectWithValue, dispatch }) => {
    let { status, response } = await themeRepo.themeUpdate(id, data)

    if (!status) {
      dispatch(
        show({
          content: response,
          severity: 'error',
        })
      )
      return rejectWithValue(response)
    }

    dispatch(
      show({
        content: 'Theme update success',
        severity: 'success',
      })
    )

    return response
  }
)

export const themeMain = createAsyncThunk(
  'themes/main',
  async (params: any, { rejectWithValue, dispatch, getState }) => {
    let state: any = getState()
    let newParams = { ...params }
    if (state.managerStore.store) {
      newParams.store_id = state.managerStore.store.ID
    }

    let { status, response } = await themeRepo.themeMain(newParams)

    if (!status) {
      dispatch(
        show({
          content: response,
          severity: 'error',
        })
      )
      return rejectWithValue(response)
    }

    return response
  }
)

interface ThemeState {
  isLoading: boolean
  deleteSubmitting: boolean
  theme: any
  themesLoading: any
  themes: any
  themesPagination: any
  themesFilters: any
}

const initialState: ThemeState = {
  isLoading: false,
  deleteSubmitting: false,
  theme: null,
  themesLoading: false,
  themes: [],
  themesPagination: {
    limit: 20,
    page: 1,
    total_pages: 1,
    total_rows: 1,
  },
  themesFilters: {
    page: 1,
    limit: 20,
    store_id: 0,
    name: '',
  },
}

const managerThemeSlice = createSlice({
  name: 'managerTheme',
  initialState,
  reducers: {
    setThemesFilters(state, action: PayloadAction<any>) {
      state.themesFilters = action.payload
    },
  },
  extraReducers: (builder) => {
    builder.addCase(themeCreate.pending, (state) => {
      state.isLoading = true
    })

    builder.addCase(themeCreate.fulfilled, (state, action) => {
      state.isLoading = false
    })

    builder.addCase(themeCreate.rejected, (state, action) => {
      state.isLoading = false
    })

    builder.addCase(themeList.pending, (state) => {
      state.themesLoading = true
    })

    builder.addCase(themeList.fulfilled, (state, action) => {
      state.themes = action.payload.data
      state.themesPagination = action.payload.pagination
      state.themesLoading = false
    })

    builder.addCase(themeList.rejected, (state, action) => {
      state.themesLoading = false
    })

    builder.addCase(themeDelete.pending, (state) => {
      state.deleteSubmitting = true
    })

    builder.addCase(themeDelete.fulfilled, (state, action) => {
      state.deleteSubmitting = false
    })

    builder.addCase(themeDelete.rejected, (state, action) => {
      state.deleteSubmitting = false
    })

    builder.addCase(themeMain.pending, (state) => {})

    builder.addCase(themeMain.fulfilled, (state, action) => {
      state.theme = action.payload.data
    })

    builder.addCase(themeMain.rejected, (state, action) => {})

    builder.addCase(themeUpdate.pending, (state) => {})

    builder.addCase(themeUpdate.fulfilled, (state, action) => {})

    builder.addCase(themeUpdate.rejected, (state, action) => {})
  },
})

export const { setThemesFilters } = managerThemeSlice.actions
export default managerThemeSlice.reducer
