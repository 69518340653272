import { Outfit, Source_Code_Pro } from '@next/font/google'
import { createTheme } from '@mui/material/styles'
import { red } from '@mui/material/colors'

export const outfit = Outfit({
  weight: ['300', '400', '500', '700'],
  subsets: ['latin'],
  display: 'swap',
  fallback: ['Helvetica', 'Arial', 'sans-serif'],
})

export const sourceCodePro = Source_Code_Pro({
  weight: ['300', '400', '500', '700'],
  subsets: ['latin'],
  display: 'swap',
  fallback: ['Helvetica', 'Arial', 'sans-serif'],
})

// Create a theme instance.
const theme = createTheme({
  palette: {
    primary: {
      light: '#46474B',
      main: '#FF3366',
    },
    secondary: {
      light: '#58585A',
      main: '#000000',
      dark: '#333333',
    },
    success: {
      main: '#34A853',
    },
    info: {
      main: '#ACACAC',
      light: '#34A853',
    },
    error: {
      main: red.A400,
      light: '',
    },
  },
  typography: {
    fontFamily: outfit.style.fontFamily,
    h2: {
      fontSize: 24,
      fontWeight: 700,
    },
    h3: {
      fontSize: 24,
      fontWeight: 400,
      letterSpacing: 0.4,
    },
    h4: {
      fontSize: 16,
      fontWeight: 500,
      letterSpacing: 0.5,
    },
    body1: {
      fontSize: 15,
      margin: 0,
    },
    body2: {
      fontSize: 12,
      margin: 0,
    },
  },
})

export default theme
