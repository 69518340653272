import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import StoreRepository from '@/app/repositories/StoreRepository'
import { show } from '@/features/notify/notify-slice'

const storeRepo = new StoreRepository()

export const storeBySubdomain = createAsyncThunk(
  'stores/subdomain',
  async (params: any, { rejectWithValue, dispatch }) => {
    let { status, response } = await storeRepo.storeBySubdomain(params)

    if (!status) {
      dispatch(
        show({
          content: response,
          severity: 'error',
        })
      )
      return rejectWithValue(response)
    }

    return response
  }
)

export const storeList = createAsyncThunk(
  'stores/list',
  async (params: any, { rejectWithValue, dispatch, getState }) => {
    let state: any = getState()

    let { status, response } = await storeRepo.storeList(params)

    if (!status) {
      dispatch(
        show({
          content: response,
          severity: 'error',
        })
      )
      return rejectWithValue(response)
    }

    return response
  }
)

export const storeUpdate = createAsyncThunk(
  'manager/stores/update',
  async ({ id, data }: { id: any; data: any }, { rejectWithValue, dispatch }) => {
    let { status, response } = await storeRepo.storeUpdate(id, data)

    if (!status) {
      dispatch(
        show({
          content: response,
          severity: 'error',
        })
      )
      return rejectWithValue(response)
    }

    return response
  }
)

interface ManagerStoreState {
  store: any
  stores: Array<object>
  storesPagination: any
  storeUpdating: boolean
}

const initialState: ManagerStoreState = {
  store: null,
  stores: [],
  storesPagination: null,
  storeUpdating: false,
}

const managerStoreSlice = createSlice({
  name: 'managerStore',
  initialState,
  reducers: {},
  extraReducers: (builder: any) => {
    builder.addCase(storeBySubdomain.pending, (state: any) => {})

    builder.addCase(storeBySubdomain.fulfilled, (state: any, action: any) => {
      state.store = action.payload.data
    })

    builder.addCase(storeBySubdomain.rejected, (state: any, action: any) => {})

    builder.addCase(storeList.pending, (state: any) => {})

    builder.addCase(storeList.fulfilled, (state: any, action: any) => {
      state.stores = action.payload.data
      state.storesPagination = action.payload.pagination
    })

    builder.addCase(storeList.rejected, (state: any, action: any) => {})

    builder.addCase(storeUpdate.pending, (state: any) => {
      state.storeUpdating = true
    })

    builder.addCase(storeUpdate.fulfilled, (state: any, action: any) => {
      state.store = action.payload.data
      state.storeUpdating = false
    })

    builder.addCase(storeUpdate.rejected, (state: any) => {
      state.storeUpdating = false
    })
  },
})

export const {} = managerStoreSlice.actions
export default managerStoreSlice.reducer
