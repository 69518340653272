import BaseRepository from './BaseRepository'

class CollectionRepository extends BaseRepository {
  url() {
    return 'collections'
  }

  handlerHttpError(e: any) {
    if (e.response && e.response.data) {
      let errorMsg = e.response.data.msg
      return this.error(errorMsg)
    } else {
      throw e
    }
  }

  async collectionList(query: any, headers = {}) {
    try {
      let response = await this.httpClient.get(this.url(), {
        params: query,
        headers: headers,
      })
      return this.success(response.data)
    } catch (e) {
      return this.handlerHttpError(e)
    }
  }

  async collectionFeatured(query: any, headers = {}) {
    try {
      let response = await this.httpClient.get(this.url() + '/featured', {
        params: query,
        headers: headers,
      })
      return this.success(response.data)
    } catch (e) {
      return this.handlerHttpError(e)
    }
  }

  async collectionCreate(data: any, query = {}, headers = {}) {
    let formData = new FormData()
    formData.append('name', data.name)
    formData.append('store_id', data.store_id)
    formData.append('description', data.description)
    formData.append('is_featured', data.is_featured ? '1' : '0')
    if (data.image) {
      formData.append('image', data.image)
    }

    headers = {
      'Content-Type': 'multipart/form-data',
    }
    try {
      let response = await this.httpClient.post(this.url(), formData, {
        params: query,
        headers: headers,
      })
      return this.success(response.data)
    } catch (e) {
      return this.handlerHttpError(e)
    }
  }

  async collectionUpdate(id: any, data: any, query = {}, headers = {}) {
    let formData = new FormData()
    formData.append('name', data.name)
    formData.append('store_id', data.store_id)
    formData.append('description', data.description)
    formData.append('is_featured', data.is_featured ? '1' : '0')
    if (data.image) {
      formData.append('image', data.image)
    }

    headers = {
      'Content-Type': 'multipart/form-data',
    }
    try {
      let response = await this.httpClient.put(this.url() + `/${id}`, formData, {
        params: query,
        headers: headers,
      })
      return this.success(response.data)
    } catch (e) {
      return this.handlerHttpError(e)
    }
  }
}

export default CollectionRepository
