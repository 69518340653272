import BaseRepository from './BaseRepository'
class OrderRepository extends BaseRepository {
  url() {
    return '/'
  }
  handlerHttpError(e: any) {
    if (e.response && e.response.data) {
      let errorMsg = e.response.data.msg
      return this.error(errorMsg)
    } else {
      throw e
    }
  }

  async orderAdd(data: any, query = {}, headers = {}) {
    try {
      let response = await this.httpClient.post(this.url() + 'orders', data, {
        params: query,
        headers: headers,
      })
      return this.success(response.data)
    } catch (e) {
      return this.handlerHttpError(e)
    }
  }
  

  async orderList(query: any, headers = {}) {
    try {
      let response = await this.httpClient.get(this.url() + 'orders', {
        params: query,
        headers: headers,
      })
      return this.success(response.data)
    } catch (e) {
      return this.handlerHttpError(e)
    }
  }

  async orderUpdate(data: any, query = {}, headers = {}) {
    try {
      let response = await this.httpClient.post(this.url() + `orders/update`, data, {
        params: query,
        headers: headers,
      })
      return this.success(response.data)
    } catch (e) {
      return this.handlerHttpError(e)
    }
  }
  async orderDelete(data: any, query = {}, headers = {}) {
    try {
      let response = await this.httpClient.delete(this.url() + 'orders', {
        params: {
          store_id: data.store_id,
          table_id: data.table_id,
          order_token: data.order_token,
        },
        headers: headers,
      })
      return this.success(response.data)
    } catch (e) {
      return this.handlerHttpError(e)
    }
  }
  async orderEmpty(data: any) {
    try {
      let response = await this.httpClient.post(this.url() + 'orders/empty', data)
      return this.success(response.data)
    } catch (e) {
      return this.handlerHttpError(e)
    }
  }
  async orderPay(data: any) {
    try {
      let response = await this.httpClient.post(this.url() + 'orders/pay', data)
      return this.success(response.data)
    } catch (e) {
      return this.handlerHttpError(e)
    }
  }
}

export default OrderRepository
