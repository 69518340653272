import BaseRepository from '../BaseRepository'

class TableRepository extends BaseRepository {
  url() {
    return '/'
  }

  handlerHttpError(e: any) {
    if (e.response && e.response.data) {
      let errorMsg = e.response.data.msg
      return this.error(errorMsg)
    } else {
      throw e
    }
  }
async tableDetail(query: any, headers = {}) {
    headers = {
      Authorization: 'Bearer ' + query.token,
    }
    try {
      let response = await this.httpClient.get(this.url() + `tables/${query.table_id}`, {
        params: query,
        headers: headers,
      })
      return this.success(response.data)
    } catch (e) {
      return this.handlerHttpError(e)
    }
  }
}

export default TableRepository
