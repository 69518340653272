import { createSlice, PayloadAction } from '@reduxjs/toolkit'

interface NotifyState {
  step: number,
  coverImage: any,
}

const initialState: NotifyState = {
  step: 1,
  coverImage: {}
}

const onboardingSlice = createSlice({
  name: 'onboarding',
  initialState,
  reducers: {
    setStep(state, action: PayloadAction<any>) {
      state.step = action.payload
    },
    setCoverImage(state, action: PayloadAction<any>) {
      state.coverImage = action.payload
    },
  },
})

export const { setStep, setCoverImage } = onboardingSlice.actions
export default onboardingSlice.reducer
