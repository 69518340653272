import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import AuthRepository from '../../app/repositories/AuthRepository'
import UserRepository from '../../app/repositories/UserRepository'
import { show } from '../notify/notify-slice'
import { getCookie, setCookie, removeCookie } from 'typescript-cookie'

const authRepo = new AuthRepository()
const userRepo = new UserRepository()

export const signIn = createAsyncThunk(
  'user/sign/in',
  async (data: any, { rejectWithValue, dispatch }) => {
    let { status, response } = await authRepo.signIn(data)

    if (!status) {
      dispatch(
        show({
          content: response,
          severity: 'error',
        })
      )
      return rejectWithValue(response)
    }

    dispatch(
      show({
        content: 'Sign in success',
        severity: 'success',
      })
    )
    setCookie('dd_tokens', JSON.stringify(response.tokens), {
      sameSite: 'lax',
    })

    return response
  }
)

export const signUp = createAsyncThunk(
  'user/sign/up',
  async (data: any, { rejectWithValue, dispatch }) => {
    let { status, response } = await authRepo.signUp(data)

    if (!status) {
      dispatch(
        show({
          content: response,
          severity: 'error',
        })
      )
      return rejectWithValue(response)
    }

    dispatch(
      show({
        content: 'Sign up success',
        severity: 'success',
      })
    )

    return response
  }
)

export const signOut = createAsyncThunk(
  'user/sign/out',
  async (data: any, { rejectWithValue, dispatch, getState }) => {
    try {
      let state: any = getState()
      data.token = state.user?.tokens.access
      let { status, response } = await authRepo.signOut(data)

      if (!status) {
        dispatch(
          show({
            content: response,
            severity: 'error',
          })
        )
        return rejectWithValue(response)
      }

      dispatch(
        show({
          content: 'Sign out success',
          severity: 'success',
        })
      )

      removeCookie('dd_tokens')

      return response
    } catch (error) {
      console.log(error)
    }
  }
)

export const googleLogin = async (params = {}) => {
  let { status, response } = await authRepo.googleLogin(params)

  if (!status) {
    return response
  }

  return response
}

export const googleLoginCallback = createAsyncThunk(
  'oauth/google/callback',
  async (params: any, { rejectWithValue, dispatch }) => {
    let { status, response } = await authRepo.googleLoginCallback(params)

    if (!status) {
      dispatch(
        show({
          content: response,
          severity: 'error',
        })
      )
      return rejectWithValue(response)
    }

    dispatch(
      show({
        content: 'Google sign in success',
        severity: 'success',
      })
    )

    setCookie('dd_tokens', JSON.stringify(response.tokens), {
      //   domain: `.localhost`,
    })

    return response
  }
)

export const resetPassword = createAsyncThunk(
  'user/reset-password',
  async (data: any, { rejectWithValue, dispatch }) => {
    let { status, response } = await authRepo.resetPassword(data)

    if (!status) {
      dispatch(
        show({
          content: response,
          severity: 'error',
        })
      )
      return rejectWithValue(response)
    }

    dispatch(
      show({
        content: 'Reset password success please check email',
        severity: 'success',
      })
    )

    return response
  }
)

export const createPassword = createAsyncThunk(
  'user/create-password',
  async (data: any, { rejectWithValue, dispatch, getState }) => {
    let state: any = getState()
    data.token = data.token ? data.token : state.user?.tokens.access

    let { status, response } = await authRepo.createPassword(data)

    if (!status) {
      dispatch(
        show({
          content: response,
          severity: 'error',
        })
      )
      return rejectWithValue(response)
    }

    dispatch(
      show({
        content: 'Reset password success',
        severity: 'success',
      })
    )

    return response
  }
)

export const fetchProfile = createAsyncThunk(
  'users/profile',
  async (params: any, { rejectWithValue, dispatch }) => {
    let { status, response } = await userRepo.profile(params)

    if (!status) {
      dispatch(
        show({
          content: response,
          severity: 'error',
        })
      )
      return rejectWithValue(response)
    }

    return response
  }
)

interface UserState {
  isLoading: boolean
  errorMessage: string
  tokens: any
  isAuthenticated: boolean
  user: {}
  isRegistered: boolean
  isCreatePassword: boolean
  isSignOut: boolean
  profile: {}
}

let userToken = null

if (typeof document !== 'undefined') {
  userToken = getCookie('dd_tokens') ? getCookie('dd_tokens') : null
}

const initialState: UserState = {
  isLoading: false,
  errorMessage: '',
  tokens: userToken ? JSON.parse(`${userToken}`) : {},
  isAuthenticated: false,
  user: {},
  isRegistered: false,
  isCreatePassword: false,
  isSignOut: false,
  profile: {},
}

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setTokens(state: any, payload: any) {
      state.tokens = payload
    },
    resetStateLogin(state: any) {
      state.isAuthenticated = false
    },
    resetStateSignUp(state: any) {
      state.isRegistered = false
    },
    resetStateCreatePassword(state: any) {
      state.isCreatePassword = false
    },
    resetStateSignOut(state: any) {
      state.isSignOut = false
    },
  },
  extraReducers: (builder: any) => {
    builder.addCase(signIn.pending, (state: any) => {
      state.isLoading = true
      state.isAuthenticated = false
    })

    builder.addCase(signIn.fulfilled, (state: any, action: any) => {
      state.isLoading = false
      state.tokens = action.payload.tokens
      state.isAuthenticated = true
    })

    builder.addCase(signIn.rejected, (state: any, action: any) => {
      state.isLoading = false
    })

    builder.addCase(signUp.pending, (state: any) => {
      state.isLoading = true
      state.isRegistered = false
    })

    builder.addCase(signUp.fulfilled, (state: any, action: any) => {
      state.isLoading = false
      state.user = action.payload.user
      state.isRegistered = true
      state.isAuthenticated = false
    })

    builder.addCase(signUp.rejected, (state: any, action: any) => {
      state.isLoading = false
    })

    builder.addCase(signOut.pending, (state: any) => {
      state.isLoading = true
      state.isRegistered = false
    })

    builder.addCase(signOut.fulfilled, (state: any, action: any) => {
      state.isLoading = false
      state.user = {}
      state.tokens = {}
      state.isSignOut = true
    })

    builder.addCase(signOut.rejected, (state: any, action: any) => {
      state.isLoading = false
    })

    builder.addCase(googleLoginCallback.pending, (state: any) => {
      state.isLoading = true
    })

    builder.addCase(googleLoginCallback.fulfilled, (state: any, action: any) => {
      state.isLoading = false
      state.tokens = action.payload.tokens
      state.isAuthenticated = true
      state.isRegistered = false
    })

    builder.addCase(googleLoginCallback.rejected, (state: any, action: any) => {
      state.isLoading = false
    })

    builder.addCase(resetPassword.pending, (state: any) => {
      state.isLoading = true
    })

    builder.addCase(resetPassword.fulfilled, (state: any, action: any) => {
      state.isLoading = false
    })

    builder.addCase(resetPassword.rejected, (state: any, action: any) => {
      state.isLoading = false
    })

    builder.addCase(createPassword.pending, (state: any) => {
      state.isLoading = true
    })

    builder.addCase(createPassword.fulfilled, (state: any, action: any) => {
      state.isLoading = false
      state.isCreatePassword = true
      state.isAuthenticated = false
    })

    builder.addCase(createPassword.rejected, (state: any, action: any) => {
      state.isLoading = false
    })

    builder.addCase(fetchProfile.pending, (state: any) => {})

    builder.addCase(fetchProfile.fulfilled, (state: any, action: any) => {
      state.profile = action.payload.data
    })

    builder.addCase(fetchProfile.rejected, (state: any, action: any) => {})
  },
})

export const {
  setTokens,
  resetStateLogin,
  resetStateSignUp,
  resetStateCreatePassword,
  resetStateSignOut,
} = userSlice.actions
export default userSlice.reducer
