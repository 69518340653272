import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import StaffRepository from '../../app/repositories/StaffRepository'
import { show } from '../notify/notify-slice'

const staffRepo = new StaffRepository()

export const sessionCreate = createAsyncThunk(
    'sessionOrder/create',
    async (data: any, { rejectWithValue, dispatch, getState }) => {
        let state: any = getState()
        data.token = data.token ? data.token : state.user?.tokens.access
        data.store_id = data.store_id ? data.store_id : state.store.store.ID
        let { status, response } = await staffRepo.sessionCreate(data)

        if (!status) {
            dispatch(
                show({
                    content: response,
                    severity: 'error',
                })
            )
            return rejectWithValue(response)
        }

        // dispatch(
        //     show({
        //         content: 'Store create success',
        //         severity: 'success',
        //     })
        // )
        return response
    }
)

interface StaffState {
    callStatus: boolean,
    isLoading: boolean,
    isCreated: boolean,
    order: Array<any>,
}

const initialState: StaffState = {
    callStatus: true,
    isLoading: false,
    isCreated: false,
    order: [],
}

const staffSlice = createSlice({
    name: 'staff',
    initialState,
    reducers: {
        setCallStaff(state: any, action: PayloadAction<any>) {
            state.callStatus = action.payload
        },
    },
    extraReducers: (builder) => {
        builder.addCase(sessionCreate.pending, (state) => {
            state.isLoading = true
        })

        builder.addCase(sessionCreate.fulfilled, (state, action) => {
            state.isLoading = false
            state.order = action.payload.data
        })

        builder.addCase(sessionCreate.rejected, (state, action) => {
            state.isLoading = false
        })

    },
})

export const { setCallStaff } = staffSlice.actions
export default staffSlice.reducer