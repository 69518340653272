import BaseRepository from './BaseRepository'

class UserRepository extends BaseRepository {
  url() {
    return '/'
  }

  handlerHttpError(e: any) {
    if (e.response && e.response.data) {
      let errorMsg = e.response.data.msg
      return this.error(errorMsg)
    } else {
      throw e
    }
  }

  async signIn(data: any, query = {}, headers = {}) {
    try {
      let response = await this.httpClient.post(this.url() + 'users/sign/in', data, {
        params: query,
        headers: headers,
      })
      return this.success(response.data)
    } catch (e) {
      return this.handlerHttpError(e)
    }
  }

  async signOut(data: any, query = {}, headers = {}) {
    try {
      let response = await this.httpClient.post(this.url() + 'users/sign/out', data, {
        params: query,
        headers: headers,
      })
      return this.success(response.data)
    } catch (e) {
      return this.handlerHttpError(e)
    }
  }

  async signUp(data: any, query = {}, headers = {}) {
    try {
      let response = await this.httpClient.post(this.url() + 'users/sign/up', data, {
        params: query,
        headers: headers,
      })
      return this.success(response.data)
    } catch (e) {
      return this.handlerHttpError(e)
    }
  }

  async googleLogin(query = {}, headers = {}) {
    try {
      let response = await this.httpClient.get(this.url() + 'oauth/google/login', {
        params: query,
        headers: headers,
      })
      return this.success(response.data)
    } catch (e) {
      return this.handlerHttpError(e)
    }
  }

  async googleLoginCallback(query = {}, headers = {}) {
    try {
      let response = await this.httpClient.get(this.url() + 'oauth/google/callback', {
        params: query,
        headers: headers,
      })
      return this.success(response.data)
    } catch (e) {
      return this.handlerHttpError(e)
    }
  }

  async resetPassword(data: any, query = {}, headers = {}) {
    try {
      let response = await this.httpClient.post(this.url() + 'users/reset-password', data, {
        params: query,
        headers: headers,
      })
      return this.success(response.data)
    } catch (e) {
      return this.handlerHttpError(e)
    }
  }

  async createPassword(data: any, query = {}, headers = {}) {
    try {
      let response = await this.httpClient.post(this.url() + 'users/create-password', data, {
        params: query,
        headers: headers,
      })
      return this.success(response.data)
    } catch (e) {
      return this.handlerHttpError(e)
    }
  }

  async userSocialCallback(data: any, query = {}, headers = {}) {
    try {
      let response = await this.httpClient.post(this.url() + 'users/social/callback', data, {
        params: query,
        headers: headers,
      })
      return this.success(response.data)
    } catch (e) {
      return this.handlerHttpError(e)
    }
  }
}

export default UserRepository
