import {
  ActionReducerMapBuilder,
  createAsyncThunk,
  createSlice,
  PayloadAction,
} from '@reduxjs/toolkit'
import UserRepository from '@/app/repositories/UserRepository'
import { show } from '@/features/notify/notify-slice'

const userRepo = new UserRepository()

export const fetchProfile = createAsyncThunk(
  'manager/users/profile',
  async (params: any, { rejectWithValue, dispatch }) => {
    let { status, response } = await userRepo.profile(params)

    if (!status) {
      dispatch(
        show({
          content: response,
          severity: 'error',
        })
      )
      return rejectWithValue(response)
    }

    return response
  }
)

export const userUpdate = createAsyncThunk(
  'manager/users/update',
  async (data: any, { rejectWithValue, dispatch }) => {
    let { status, response } = await userRepo.userUpdate(data)

    if (!status) {
      dispatch(
        show({
          content: response,
          severity: 'error',
        })
      )
      return rejectWithValue(response)
    }

    return response
  }
)

interface ManagerUserState {
  profile: any
}

const initialState: ManagerUserState = {
  profile: null,
}

const managerUserSlice = createSlice({
  name: 'managerUser',
  initialState,
  reducers: {},
  extraReducers: (builder: ActionReducerMapBuilder<ManagerUserState>) => {
    builder.addCase(fetchProfile.pending, (state: ManagerUserState) => {})

    builder.addCase(
      fetchProfile.fulfilled,
      (state: ManagerUserState, action: PayloadAction<any>) => {
        state.profile = action.payload.data
      }
    )

    builder.addCase(fetchProfile.rejected, (state: ManagerUserState) => {})
  },
})

export const {} = managerUserSlice.actions
export default managerUserSlice.reducer
