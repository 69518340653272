import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import NotificationRepository from '../../app/repositories/NotificationRepository'
import { getCookie } from 'typescript-cookie'
const notificationRepo = new NotificationRepository()
export const notificationAdd = createAsyncThunk(
    'notifications/add',
    async (params: any, { rejectWithValue, dispatch, getState }) => {
        params.store_id = params.store_id ? params.store_id : 2
        if (typeof document !== 'undefined') {
            let { response } = await notificationRepo.notificationAdd({ ...params, table_id: Number(params.table_id), notification_token: getCookie('notification_token') })
            return response
        }
    }
)
export const notificationUpdate = createAsyncThunk(
    'notifications/update',
    async (params: any, { rejectWithValue, dispatch, getState }) => {
        params.store_id = params.store_id ? params.store_id : 2
        if (typeof document !== 'undefined') {
            let { response } = await notificationRepo.notificationUpdate({ ...params, table_id: Number(params.table_id), notification_token: getCookie('notification_token') })
            return response
        }
    }
)

export const notificationList = createAsyncThunk(
    'notifications/list',
    async (params: any, { rejectWithValue, dispatch, getState }) => {
        params.store_id = params.store_id ? params.store_id : 2
        let { response } = await notificationRepo.notificationList({
            ...params,
            table_id: Number(params.table_id),
            notification_token: getCookie('notification_token')
        })
        return response
    }
)
export const notificationDelete = createAsyncThunk(
    'notifications/delete',
    async (params: any, { rejectWithValue, dispatch, getState }) => {
        params.store_id = params.store_id ? params.store_id : 2
        let { response } = await notificationRepo.notificationDelete({
            ...params,
            table_id: Number(params.table_id),
            notification_token: getCookie('notification_token')
        })
        return response
    }
)

interface NotificationState {
    isLoading: boolean
    isLoadingList: boolean
    productSelect: Object,
    notification: Array<any>,
    show: boolean,
}


const initialState: NotificationState = {
    isLoading: false,
    isLoadingList: false,
    productSelect: {},
    notification: [],
    show: false,
}

const notificationSlice = createSlice({
    name: 'notifications',
    initialState,
    reducers: {
        toggleNotification(state: any, action: PayloadAction<any>) {
            state.show = action.payload
        },
    },
    extraReducers: (builder: any) => {

        builder.addCase(notificationList.rejected, (state: any, action: any) => { })
        builder.addCase(notificationAdd.pending, (state: any) => {
            state.isLoading = true
        })

        builder.addCase(notificationAdd.fulfilled, (state: any, action: any) => {
            state.isLoading = false
            state.store = action.payload.data
            state.isCreated = true
        })

        builder.addCase(notificationAdd.rejected, (state: any, action: any) => {
            state.isLoading = false
        })
        builder.addCase(notificationDelete.pending, (state: any) => {
            state.isLoading = true
        })

        builder.addCase(notificationDelete.fulfilled, (state: any, action: any) => {
            state.isLoading = false
        })

        builder.addCase(notificationDelete.rejected, (state: any, action: any) => {
            state.isLoading = false
        })

    }
})

export const { toggleNotification } = notificationSlice.actions
export default notificationSlice.reducer