import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import AppRepository from '../../app/repositories/AppRepository'
import { show } from '../notify/notify-slice'

const appRepo = new AppRepository()

export const kiotvietConnect = createAsyncThunk(
  'app/kiotviet/connect',
  async (data: any, { rejectWithValue, dispatch }) => {
    data.scopes = 'PublicApi.Access'
    data.gateway = 'kiotviet'
    let { status, response } = await appRepo.kiotvietConnect(data)

    if (!status) {
      dispatch(
        show({
          content: response,
          severity: 'error',
        })
      )
      return rejectWithValue(response)
    }

    dispatch(
      show({
        content: 'KiotViet connect success',
        severity: 'success',
      })
    )
    return response
  }
)

export const sapoConnect = createAsyncThunk(
  'app/sapo/connect',
  async (params: any, { rejectWithValue, dispatch }) => {
    let { status, response } = await appRepo.sapoConnect(params)

    if (!status) {
      dispatch(
        show({
          content: response,
          severity: 'error',
        })
      )
      return rejectWithValue(response)
    }

    return response
  }
)

export const appList = createAsyncThunk(
  'app/list',
  async (params: any, { rejectWithValue, dispatch }) => {
    let { status, response } = await appRepo.list(params)

    if (!status) {
      dispatch(
        show({
          content: response,
          severity: 'error',
        })
      )
      return rejectWithValue(response)
    }

    return response
  }
)

interface AppState {
  isKiotVietLoading: boolean
  isSapoLoading: boolean
  apps: any
  appsPagination: any
}

const initialState: AppState = {
  isKiotVietLoading: false,
  isSapoLoading: false,
  apps: [],
  appsPagination: {
    limit: 20,
    page: 1,
    total_pages: 1,
    total_rows: 4,
  },
}

const appSlice = createSlice({
  name: 'app',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(kiotvietConnect.pending, (state) => {
      state.isKiotVietLoading = true
    })

    builder.addCase(kiotvietConnect.fulfilled, (state, action) => {
      state.isKiotVietLoading = false
    })

    builder.addCase(kiotvietConnect.rejected, (state, action) => {
      state.isKiotVietLoading = false
    })

    builder.addCase(sapoConnect.pending, (state) => {
      state.isSapoLoading = true
    })

    builder.addCase(sapoConnect.fulfilled, (state, action) => {
      state.isSapoLoading = false
    })

    builder.addCase(sapoConnect.rejected, (state, action) => {
      state.isSapoLoading = false
    })

    builder.addCase(appList.pending, (state) => {})

    builder.addCase(appList.fulfilled, (state, action) => {
      state.apps = action.payload.data
      state.appsPagination = action.payload.pagination
    })

    builder.addCase(appList.rejected, (state, action) => {})
  },
})

export const {} = appSlice.actions
export default appSlice.reducer
