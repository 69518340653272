import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import MenuRepository from '@/app/repositories/MenuRepository'
import { show } from '../notify/notify-slice'

const menuRepo = new MenuRepository()

export const menuCreate = createAsyncThunk(
  'menu/create',
  async (data: any, { rejectWithValue, dispatch, getState }) => {
    let state: any = getState()
    data.store_id = data.store_id ? data.store_id : null
    if (state.store.stores.length && !data.store_id) {
      data.store_id = state.store.stores[0].ID
    }
    let { status, response } = await menuRepo.menuCreate(data)

    if (!status) {
      dispatch(
        show({
          content: response,
          severity: 'error',
        })
      )
      return rejectWithValue(response)
    }

    dispatch(
      show({
        content: 'Menu create success',
        severity: 'success',
      })
    )
    return response
  }
)

export const menuList = createAsyncThunk(
  'menu/list',
  async (params: any, { rejectWithValue, dispatch, getState }) => {
    let state: any = getState()
    params.store_id = params.store_id ? params.store_id : null
    if (state.store.stores.length && !params.store_id) {
      params.store_id = state.store.stores[0].ID
    }

    let { status, response } = await menuRepo.menuList(params)

    if (!status) {
      dispatch(
        show({
          content: response,
          severity: 'error',
        })
      )
      return rejectWithValue(response)
    }

    return response
  }
)

export const menuDelete = createAsyncThunk(
  'menu/delete',
  async ({ id, params }: { id: number; params: any }, { rejectWithValue, dispatch, getState }) => {
    let state: any = getState()
    params.store_id = params.store_id ? params.store_id : null
    if (state.store.stores.length && !params.store_id) {
      params.store_id = state.store.stores[0].ID
    }

    let { status, response } = await menuRepo.delete(id, params)

    if (!status) {
      dispatch(
        show({
          content: response,
          severity: 'error',
        })
      )
      return rejectWithValue(response)
    }

    dispatch(
      show({
        content: 'Menu delete success',
        severity: 'success',
      })
    )

    return response
  }
)

interface AppState {
  isLoading: boolean
  deleteSubmitting: boolean
  menus: any
  menusPagination: any
}

const initialState: AppState = {
  isLoading: false,
  deleteSubmitting: false,
  menus: [],
  menusPagination: {
    limit: 20,
    page: 1,
    total_pages: 1,
    total_rows: 4,
  },
}

const menuSlice = createSlice({
  name: 'menu',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(menuCreate.pending, (state) => {
      state.isLoading = true
    })

    builder.addCase(menuCreate.fulfilled, (state, action) => {
      state.isLoading = false
    })

    builder.addCase(menuCreate.rejected, (state, action) => {
      state.isLoading = false
    })

    builder.addCase(menuList.pending, (state) => {})

    builder.addCase(menuList.fulfilled, (state, action) => {
      state.menus = action.payload.data
      state.menusPagination = action.payload.pagination
    })

    builder.addCase(menuList.rejected, (state, action) => {})

    builder.addCase(menuDelete.pending, (state) => {
      state.deleteSubmitting = true
    })

    builder.addCase(menuDelete.fulfilled, (state, action) => {
      state.deleteSubmitting = false
    })

    builder.addCase(menuDelete.rejected, (state, action) => {
      state.deleteSubmitting = false
    })
  },
})

export const {} = menuSlice.actions
export default menuSlice.reducer
