import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import OrderRepository from '../../app/repositories/OrderRepository'
import { getCookie } from 'typescript-cookie'
const orderRepo = new OrderRepository()
export const orderAdd = createAsyncThunk(
    'orders/add',
    async (params: any, { rejectWithValue, dispatch, getState }) => {
        params.store_id = params.store_id ? params.store_id : 2
        if (typeof document !== 'undefined') {
            let { response } = await orderRepo.orderAdd({ ...params, table_id: Number(params.table_id), cart_token: localStorage.getItem('cart_token') })
            return response
        }
    }
)
export const orderUpdate = createAsyncThunk(
    'orders/update',
    async (params: any, { rejectWithValue, dispatch, getState }) => {
        params.store_id = params.store_id ? params.store_id : 2
        if (typeof document !== 'undefined') {
            let { response } = await orderRepo.orderUpdate({ ...params, table_id: Number(params.table_id), cart_token: localStorage.getItem('cart_token') })
            return response
        }
    }
)

export const orderList = createAsyncThunk(
    'orders/list',
    async (params: any, { rejectWithValue, dispatch, getState }) => {
        params.store_id = params.store_id ? params.store_id : 2
        let { response } = await orderRepo.orderList({
            ...params,
            table_id: Number(params.table_id),
            cart_token: localStorage.getItem('cart_token')
        })
        return response
    }
)
export const orderDelete = createAsyncThunk(
    'orders/delete',
    async (params: any, { rejectWithValue, dispatch, getState }) => {
        params.store_id = params.store_id ? params.store_id : 2
        let { response } = await orderRepo.orderDelete({
            ...params,
            table_id: Number(params.table_id),
            cart_token: localStorage.getItem('cart_token')
        })
        return response
    }
)

export const orderPay = createAsyncThunk(
    'orders/pay',
    async (params: any, { rejectWithValue, dispatch, getState }) => {
        params.store_id = params.store_id ? params.store_id : 2
        let { response } = await orderRepo.orderPay({
            ...params,
            table_id: Number(params.table_id),
            cart_token: localStorage.getItem('cart_token')
        })
        return response
    }
)

interface OrderState {
    isLoading: boolean
    isLoadingList: boolean
    productsInOrder: Array<any> ,
    order: Object,
    isCreated: boolean
    showOrder: boolean,
    message: boolean,
    preview: boolean,
    payment: string,
}


const initialState: OrderState = {
    isLoading: false,
    isLoadingList: false,
    productsInOrder: [],
    order: {},
    isCreated: false,
    showOrder: false,
    message: false,
    preview: false,
    payment: '',
}

const orderSlice = createSlice({
    name: 'orders',
    initialState,
    reducers: {
        clearOrder(state: any) {
            state.order = []
        },
        paymentMethod(state: any, action: PayloadAction<any>) {
            state.payment = action.payload
        },
    },
    extraReducers: (builder: any) => {
        builder.addCase(orderList.pending, (state: any) => {
            state.isLoading = true
        })

        builder.addCase(orderList.fulfilled, (state: any, action: any) => {
            state.order = action.payload.data
            state.productsInOrder = action.payload.data?.line_items
            state.isLoading = false
        })

        builder.addCase(orderList.rejected, (state: any, action: any) => {
            state.isLoading = false
        })
        builder.addCase(orderAdd.pending, (state: any) => {
            state.isLoading = true
        })

        builder.addCase(orderAdd.fulfilled, (state: any, action: any) => {
            state.isLoading = false
            state.isCreated = true
        })

        builder.addCase(orderAdd.rejected, (state: any, action: any) => {
            state.isLoading = false
        })
        builder.addCase(orderDelete.pending, (state: any) => {
            state.isLoading = true
        })

        builder.addCase(orderDelete.fulfilled, (state: any, action: any) => {
            state.isLoading = false
        })

        builder.addCase(orderDelete.rejected, (state: any, action: any) => {
            state.isLoading = false
        })
        builder.addCase(orderPay.pending, (state: any) => {
            state.isLoading = true
        })

        builder.addCase(orderPay.fulfilled, (state: any, action: any) => {
            state.isLoading = false
        })

        builder.addCase(orderPay.rejected, (state: any, action: any) => {
            state.isLoading = false
        })

    }
})

export const { clearOrder, paymentMethod } = orderSlice.actions
export default orderSlice.reducer