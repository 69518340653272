import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import CartRepository from '../../app/repositories/CartRepository'
const cartRepo = new CartRepository()
export const cartCreate = createAsyncThunk(
    'carts/create',
    async (params: any, { rejectWithValue, dispatch, getState }) => {
        params.store_id = params.store_id ? params.store_id : 2
        let { response } = await cartRepo.cartCreate({
            ...params,
            table_id: Number(params.table_id)
        })
        localStorage.setItem('cart_token', response.data.cart_token)
        return response
    }
)
export const cartAdd = createAsyncThunk(
    'carts/add',
    async (params: any, { rejectWithValue, dispatch, getState }) => {
        params.store_id = params.store_id ? params.store_id : 2
        if (typeof document !== 'undefined') {
            let { response } = await cartRepo.cartAdd({ ...params, table_id: Number(params.table_id), cart_token: localStorage.getItem('cart_token') })
            return response
        }
    }
)

export const cartUpdate = createAsyncThunk(
    'carts/update',
    async (params: any, { rejectWithValue, dispatch, getState }) => {
        params.store_id = params.store_id ? params.store_id : 2
        if (typeof document !== 'undefined') {
            let { response } = await cartRepo.cartUpdate({ ...params, table_id: Number(params.table_id), cart_token: localStorage.getItem('cart_token') })
            return response
        }
    }
)

export const cartList = createAsyncThunk(
    'carts/list',
    async (params: any, { rejectWithValue, dispatch, getState }) => {
        params.store_id = params.store_id ? params.store_id : 2
        let { response } = await cartRepo.cartList({
            ...params,
            table_id: Number(params.table_id),
            cart_token: localStorage.getItem('cart_token')
        })
        return response
    }
)
export const cartDelete = createAsyncThunk(
    'carts/delete',
    async (params: any, { rejectWithValue, dispatch, getState }) => {
        params.store_id = params.store_id ? params.store_id : 2
        let { response } = await cartRepo.cartDelete({
            ...params,
            table_id: Number(params.table_id),
            cart_token: localStorage.getItem('cart_token')
        })
        return response
    }
)

export const cartEmpty = createAsyncThunk(
    'carts/empty',
    async (params: any, { rejectWithValue, dispatch, getState }) => {
        params.store_id = params.store_id ? params.store_id : 2
        let { response } = await cartRepo.cartEmpty({
            ...params,
            table_id: Number(params.table_id),
            cart_token: localStorage.getItem('cart_token')
        })
        return response
    }
)

interface CartState {
    isLoading: boolean
    isLoadingList: boolean
    productSelect: Object,
    cart: Array<any>,
    isCreated: boolean
    showCart: boolean,
    message: boolean,
    preview: boolean,
    payment: string,
    checkout: boolean,
    orderSuccess: boolean,
    orderConfirm: boolean,
    cartExisted: boolean,
}


const initialState: CartState = {
    isLoading: true,
    isLoadingList: false,
    productSelect: {},
    cart: [],
    isCreated: false,
    showCart: false,
    message: false,
    preview: false,
    payment: '',
    checkout: false,
    orderSuccess: false,
    orderConfirm: false,
    cartExisted: false,
}

const cartSlice = createSlice({
    name: 'carts',
    initialState,
    reducers: {
        selectProduct(state: any, action: PayloadAction<any>) {
            state.productSelect = {
                ...action.payload,
            }
        },
        addToCart(state: any, action: PayloadAction<any>) {
            return {
                ...state,
                cart: [...state.cart, action.payload],
            };
        },
        removeFromCart(state: any, action: PayloadAction<any>) {
            return {
                ...state,
                cart: state.cart?.items?.map((product: any) =>
                    product.id === action.payload.id
                        ? { ...product, quantity: 1 }
                        : product,
                ),
            }
        },
        increaseQuantity(state: any, action: PayloadAction<any>) {
            return {
                ...state,
                cart: state.cart?.items?.map((product: any) =>
                    product.id === action.payload.id && product.note === action.payload.note
                        ? { ...product, quantity: product?.quantity + action.payload.quantity }
                        : product,
                ),
            };
        },
        decreaseQuantity(state: any, action: PayloadAction<any>) {
            return {
                ...state,
                cart: state.cart?.items?.map((product: any) =>
                    product.id === action.payload.id
                        ? {
                            ...product,
                            quantity: product?.quantity !== 1 ? product?.quantity - action.payload.quantity : 1,
                        }
                        : product,
                ),
            };
        },
        toggleCart(state: any, action: PayloadAction<any>) {
            state.showCart = action.payload
        },
        togglePreview(state: any, action: PayloadAction<any>) {
            state.preview = action.payload
        },
        toggleMessage(state: any, action: PayloadAction<any>) {
            state.message = action.payload
        },
        toggleCheckout(state: any, action: PayloadAction<any>) {
            state.checkout = action.payload
        },
        toggleOrderSuccess(state: any, action: PayloadAction<any>) {
            state.orderSuccess = action.payload
        },
        toggleOrderConfirm(state: any, action: PayloadAction<any>) {
            state.orderConfirm = action.payload
        },
        toggleCartExisted(state: any, action: PayloadAction<any>) {
            state.cartExisted = action.payload
        },
        clearCart(state: any) {
            state.cart = []
        },
        paymentMethod(state: any, action: PayloadAction<any>) {
            state.payment = action.payload
        },
    },
    extraReducers: (builder: any) => {
        builder.addCase(cartCreate.pending, (state: any) => { })

        builder.addCase(cartCreate.fulfilled, (state: any, action: any) => {
            state.isLoading = false;
            state.cartExisted = false;
        })

        builder.addCase(cartCreate.rejected, (state: any, action: any) => { 
            state.cartExisted = true;
            state.isLoading = false
        })
        builder.addCase(cartList.pending, (state: any) => {
            state.isLoading = true
         })

        builder.addCase(cartList.fulfilled, (state: any, action: any) => {
            state.isLoading = true
            state.cart = action.payload?.data || []
            state.isLoading = false
        })

        builder.addCase(cartList.rejected, (state: any, action: any) => { 
            state.isLoading = false
            state.cartExisted = true;
        })
        builder.addCase(cartAdd.pending, (state: any) => {
            state.isLoading = true
        })

        builder.addCase(cartAdd.fulfilled, (state: any, action: any) => {
            state.isLoading = false
            state.store = action.payload.data
            state.isCreated = true
        })

        builder.addCase(cartAdd.rejected, (state: any, action: any) => {
            state.isLoading = false
            state.cartExisted = true;
        })
        builder.addCase(cartDelete.pending, (state: any) => {
            state.isLoading = true
        })

        builder.addCase(cartDelete.fulfilled, (state: any, action: any) => {
            state.isLoading = false
        })

        builder.addCase(cartDelete.rejected, (state: any, action: any) => {
            state.isLoading = false
        })

    }
})

export const { 
    addToCart, 
    removeFromCart, 
    increaseQuantity, 
    decreaseQuantity, 
    clearCart, 
    toggleCart, 
    togglePreview, 
    selectProduct, 
    toggleMessage, 
    paymentMethod,
    toggleCheckout,
    toggleOrderSuccess,
    toggleOrderConfirm,
    toggleCartExisted,
} = cartSlice.actions
export default cartSlice.reducer