import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import SyncDataRepository from '../../app/repositories/SyncDataRepository'
import { show } from '../notify/notify-slice'

const syncDataRepo = new SyncDataRepository()

export const sapoProductsSync = createAsyncThunk(
  'sapo/products/sync',
  async (data: any, { rejectWithValue, dispatch }) => {
    let { status, response } = await syncDataRepo.sapoProductsSync(data)

    if (!status) {
      dispatch(
        show({
          content: response,
          severity: 'error',
        })
      )
      return rejectWithValue(response)
    }

    dispatch(
      show({
        content: 'Sapo products sync success',
        severity: 'success',
      })
    )
    return response
  }
)

export const sapoCustomCollectionsSync = createAsyncThunk(
  'sapo/custom-collections/sync',
  async (data: any, { rejectWithValue, dispatch }) => {
    let { status, response } = await syncDataRepo.sapoCustomCollectionsSync(data)

    if (!status) {
      dispatch(
        show({
          content: response,
          severity: 'error',
        })
      )
      return rejectWithValue(response)
    }

    return response
  }
)

export const sapoSmartCollectionsSync = createAsyncThunk(
  'sapo/smart-collections/sync',
  async (data: any, { rejectWithValue, dispatch }) => {
    let { status, response } = await syncDataRepo.sapoSmartCollectionsSync(data)

    if (!status) {
      dispatch(
        show({
          content: response,
          severity: 'error',
        })
      )
      return rejectWithValue(response)
    }

    return response
  }
)

export const kiotvietProductsSync = createAsyncThunk(
  'kiotviet/products/sync',
  async (data: any, { rejectWithValue, dispatch }) => {
    let { status, response } = await syncDataRepo.kiotvietProductsSync(data)

    if (!status) {
      dispatch(
        show({
          content: response,
          severity: 'error',
        })
      )
      return rejectWithValue(response)
    }

    dispatch(
      show({
        content: 'KiotViet products sync success',
        severity: 'success',
      })
    )
    return response
  }
)

export const kiotvietCollectionsSync = createAsyncThunk(
  'kiotviet/collections/sync',
  async (data: any, { rejectWithValue, dispatch }) => {
    let { status, response } = await syncDataRepo.kiotvietCollectionsSync(data)

    if (!status) {
      dispatch(
        show({
          content: response,
          severity: 'error',
        })
      )
      return rejectWithValue(response)
    }
    return response
  }
)

interface SyncDataState {
  sapoProductsSyncing: boolean
  sapoCustomCollectionsSyncing: boolean
  sapoSmartCollectionsSyncing: boolean
  kiotvietProductsSyncing: boolean
  kiotvietCollectionsSyncing: boolean
}

const initialState: SyncDataState = {
  sapoProductsSyncing: false,
  sapoCustomCollectionsSyncing: false,
  sapoSmartCollectionsSyncing: false,
  kiotvietProductsSyncing: false,
  kiotvietCollectionsSyncing: false,
}

const syncDataSlice = createSlice({
  name: 'syncData',
  initialState,
  reducers: {},
  extraReducers: (builder: any) => {
    builder.addCase(sapoProductsSync.pending, (state: any) => {
      state.sapoProductsSyncing = true
    })

    builder.addCase(sapoProductsSync.fulfilled, (state: any, action: any) => {
      state.sapoProductsSyncing = false
    })

    builder.addCase(sapoProductsSync.rejected, (state: any, action: any) => {
      state.sapoProductsSyncing = false
    })

    builder.addCase(sapoCustomCollectionsSync.pending, (state: any) => {
      state.sapoCustomCollectionsSyncing = true
    })

    builder.addCase(sapoCustomCollectionsSync.fulfilled, (state: any, action: any) => {
      state.sapoCustomCollectionsSyncing = false
    })

    builder.addCase(sapoCustomCollectionsSync.rejected, (state: any, action: any) => {
      state.sapoCustomCollectionsSyncing = false
    })

    builder.addCase(sapoSmartCollectionsSync.pending, (state: any) => {
      state.sapoSmartCollectionsSyncing = true
    })

    builder.addCase(sapoSmartCollectionsSync.fulfilled, (state: any, action: any) => {
      state.sapoSmartCollectionsSyncing = false
    })

    builder.addCase(sapoSmartCollectionsSync.rejected, (state: any, action: any) => {
      state.sapoSmartCollectionsSyncing = false
    })

    builder.addCase(kiotvietProductsSync.pending, (state: any) => {
      state.kiotvietProductsSyncing = true
    })

    builder.addCase(kiotvietProductsSync.fulfilled, (state: any, action: any) => {
      state.kiotvietProductsSyncing = false
    })

    builder.addCase(kiotvietProductsSync.rejected, (state: any, action: any) => {
      state.kiotvietProductsSyncing = false
    })

    builder.addCase(kiotvietCollectionsSync.pending, (state: any) => {
      state.kiotvietCollectionsSyncing = true
    })

    builder.addCase(kiotvietCollectionsSync.fulfilled, (state: any, action: any) => {
      state.kiotvietCollectionsSyncing = false
    })

    builder.addCase(kiotvietCollectionsSync.rejected, (state: any, action: any) => {
      state.kiotvietCollectionsSyncing = false
    })
  },
})

export default syncDataSlice.reducer
