import { createSlice, PayloadAction } from '@reduxjs/toolkit'
interface ScreenState {
    isHorizontalMobile: boolean,
    isHorizontalMobileMd: boolean,
}


const initialState: ScreenState = {
    isHorizontalMobile: false,
    isHorizontalMobileMd: false,
}

const screenSlice = createSlice({
    name: 'screens',
    initialState,
    reducers: {
        checkHorizontalMobile(state: any, action: PayloadAction<any>) {
            state.isHorizontalMobile = action.payload
        },
        checkHorizontalMobileMd(state: any, action: PayloadAction<any>) {
            state.isHorizontalMobileMd = action.payload
        },
    },
    extraReducers: (builder: any) => {}
})

export const { checkHorizontalMobile, checkHorizontalMobileMd } = screenSlice.actions
export default screenSlice.reducer