import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import TableRepository from '../../../app/repositories/site/TableRepository'

const tableRepo = new TableRepository()


export const tableDetail = createAsyncThunk(
    'table/detail',
    async (params: any, { rejectWithValue, dispatch, getState }) => {
      let state: any = getState()
      params.token = params.token ? params.token : state.user?.tokens.access
  
      let { status, response } = await tableRepo.tableDetail(params)
      return response
    }
  )

interface AppState {
  isLoading: boolean
  table: any
  isCreated: boolean
}

const initialState: AppState = {
  isLoading: false,
  table: null,
  isCreated: false,
}

const appSlice = createSlice({
  name: 'app',
  initialState,
  reducers: {
  },
  extraReducers: (builder) => {
    builder.addCase(tableDetail.pending, (state) => {})

    builder.addCase(tableDetail.fulfilled, (state, action) => {
      state.table = action.payload.data
    })

    builder.addCase(tableDetail.rejected, (state, action) => {})
  },
})

export const { } = appSlice.actions
export default appSlice.reducer
