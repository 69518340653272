import BaseRepository from './BaseRepository'

class ProductRepository extends BaseRepository {
  url() {
    return '/products'
  }

  handlerHttpError(e: any) {
    if (e.response && e.response.data) {
      let errorMsg = e.response.data.msg
      return this.error(errorMsg)
    } else {
      throw e
    }
  }

  async productCreate(data: any, query = {}, headers = {}) {
    let formData = new FormData()
    formData.append('name', data.name)
    formData.append('store_id', data.store_id)
    formData.append('content', data.content)
    formData.append('price', data.price)
    formData.append('is_charge_tax', data.is_charge_tax ? '1' : '0')
    formData.append('status', data.status)
    formData.append('product_type', data.product_type)
    if (data.images?.length) {
      data.images.forEach((image: any) => {
        formData.append('images', image)
      })
    }
    if (data.menu_id) {
      formData.append('menu_id', data.menu_id)
    }
    if (data.collection_id) {
      formData.append('collection_id', data.collection_id)
    }

    headers = {
      Authorization: 'Bearer ' + data.token,
      'Content-Type': 'multipart/form-data',
    }
    try {
      let response = await this.httpClient.post(this.url(), formData, {
        params: query,
        headers: headers,
      })
      return this.success(response.data)
    } catch (e) {
      return this.handlerHttpError(e)
    }
  }

  async productList(query: any, headers = {}) {
    try {
      let response = await this.httpClient.get(this.url(), {
        params: query,
        headers: headers,
      })
      return this.success(response.data)
    } catch (e) {
      return this.handlerHttpError(e)
    }
  }

  async productDetail(query: any, headers = {}) {
    try {
      let response = await this.httpClient.get(this.url() + `/${query.id}`, {
        params: query,
        headers: headers,
      })
      return this.success(response.data)
    } catch (e) {
      return this.handlerHttpError(e)
    }
  }

  async productUpdate(id: any, data: any, query = {}, headers = {}) {
    let formData = new FormData()
    formData.append('name', data.name)
    formData.append('store_id', data.store_id)
    formData.append('content', data.content)
    formData.append('price', data.price)
    formData.append('is_charge_tax', data.is_charge_tax ? '1' : '0')
    formData.append('status', data.status)
    formData.append('product_type', data.product_type)
    if (data.images?.length) {
      data.images.forEach((image: any) => {
        formData.append('images', image)
      })
    }
    if (data.images_delete?.length) {
      data.images_delete.forEach((image: any) => {
        formData.append('images_delete', image)
      })
    }
    if (data.menu_id) {
      formData.append('menu_id', data.menu_id)
    }
    if (data.collection_id) {
      formData.append('collection_id', data.collection_id)
    }

    headers = {
      Authorization: 'Bearer ' + data.token,
      'Content-Type': 'multipart/form-data',
    }
    try {
      let response = await this.httpClient.put(this.url() + `/${id}`, formData, {
        params: query,
        headers: headers,
      })
      return this.success(response.data)
    } catch (e) {
      return this.handlerHttpError(e)
    }
  }
}

export default ProductRepository
