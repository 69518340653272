import { storeBySubdomain } from '@/features/store/store-slice'
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import CollectionRepository from '../../app/repositories/CollectionRepository'
import { show } from '../notify/notify-slice'

const collectionRepo = new CollectionRepository()

export const collectionList = createAsyncThunk(
  'collection/list',
  async (params: any, { rejectWithValue, dispatch, getState }) => {
    let state: any = getState()
    params.store_id = params.store_id ? params.store_id : 2
    let { status, response } = await collectionRepo.collectionList({
      ...params,
      includes: 'products',
      product_status: 'active',
    })

    if (!status) {
      dispatch(
        show({
          content: response,
          severity: 'error',
        })
      )
      return rejectWithValue(response)
    }

    return response
  }
)

export const collectionFeatured = createAsyncThunk(
  'collection/featured',
  async (params: any, { rejectWithValue, dispatch, getState }) => {
    let state: any = getState()
    params.store_id = params.store_id ? params.store_id : 2

    let { status, response } = await collectionRepo.collectionFeatured({
      ...params,
      is_featured: '1',
      includes: 'products',
      product_status: 'active',
    })

    if (!status) {
      dispatch(
        show({
          content: response,
          severity: 'error',
        })
      )
      return rejectWithValue(response)
    }
    return response
  }
)

interface AppState {
  isLoadingCollections: boolean
  collection: any
  collections: Array<object>
  collectionFeatureds: Array<object>
  collectionsPagination: any
  isCreated: boolean
}

const initialState: AppState = {
  isLoadingCollections: false,
  collection: null,
  collections: [],
  collectionFeatureds: [],
  collectionsPagination: {
    limit: 20,
    page: 1,
    total_pages: 1,
    total_rows: 4,
  },
  isCreated: false,
}

const appSlice = createSlice({
  name: 'app',
  initialState,
  reducers: {
    resetStateCreated(state) {
      state.isCreated = false
    },
  },
  extraReducers: (builder) => {
    builder.addCase(collectionList.pending, (state) => {
      state.isLoadingCollections = true
    })

    builder.addCase(collectionList.fulfilled, (state, action) => {
      state.collections = action.payload.data
      state.collectionsPagination = action.payload.pagination
      state.isLoadingCollections = false
    })

    builder.addCase(collectionList.rejected, (state, action) => {
      state.isLoadingCollections = false})

    builder.addCase(collectionFeatured.pending, (state) => {
      state.isLoadingCollections = true
    })

    builder.addCase(collectionFeatured.fulfilled, (state, action) => {
      state.collectionFeatureds = action.payload.data
      state.collectionsPagination = action.payload.pagination
      state.isLoadingCollections = false
    })

    builder.addCase(collectionFeatured.rejected, (state, action) => {
      state.isLoadingCollections = false
    })
  },
})

export const { resetStateCreated } = appSlice.actions
export default appSlice.reducer
