import { configureStore } from '@reduxjs/toolkit'
import { createWrapper } from 'next-redux-wrapper'
import notifyReducers from '../features/notify/notify-slice'
import userReducers from '../features/user/user-slice'
import appReducers from '../features/app/app-slice'
import cartReducers from '../features/cart/cart-slice'
import screenReducers from '../features/screen/screen-slice'
import orderReducers from '../features/order/order-slice'
import notificationReducers from '../features/notification/notification-slice'
import staffReducers from '../features/staff/staff-slice'
import storeReducers from '../features/store/store-slice'
import themeReducers from '../features/theme/theme-slice'
import productReducers from '../features/product/product-slice'
import collectionReducers from '../features/collection/collection-slice'
import onboardingReducers from '@/features/onboarding/onboarding-slice'
import menuReducers from '@/features/menu/menu-slice'
import siteProductReducers from '@/features/site/product/product-slice'
import tableReducers from '@/features/table/table-slice'
import siteTableReducers from '@/features/site/table/table-slice'
import syncDataReducers from '@/features/syncData/syncDataSlice'
import managerProductReducers from '@/features/manager/product/productSlice'
import managerStoreReducers from '@/features/manager/store/storeSlice'
import managerTableReducers from '@/features/manager/table/tableSlice'
import managerThemeReducers from '@/features/manager/theme/themeSlice'
import managerCollectionReducers from '@/features/manager/collection/collectionSlice'
import managerMenuReducers from '@/features/manager/menu/menuSlice'
import managerUserReducers from '@/features/manager/user/userSlice'
import managerCollectReducers from '@/features/manager/collect/collectSlice'

export const store = configureStore({
  reducer: {
    notify: notifyReducers,
    user: userReducers,
    app: appReducers,
    store: storeReducers,
    theme: themeReducers,
    product: productReducers,
    collection: collectionReducers,
    onboarding: onboardingReducers,
    menu: menuReducers,
    order: orderReducers,
    cart: cartReducers,
    screen: screenReducers,
    notification: notificationReducers,
    staff: staffReducers,
    siteProduct: siteProductReducers,
    table: tableReducers,
    siteTable: siteTableReducers,
    syncData: syncDataReducers,
    managerProduct: managerProductReducers,
    managerStore: managerStoreReducers,
    managerTable: managerTableReducers,
    managerTheme: managerThemeReducers,
    managerCollection: managerCollectionReducers,
    managerMenu: managerMenuReducers,
    managerUser: managerUserReducers,
    managerCollect: managerCollectReducers,
  },
})

const makeStore = () => store

export type AppDispatch = typeof store.dispatch
export type RootState = ReturnType<typeof store.getState>
export type AppStore = ReturnType<typeof makeStore>

export const wrapper = createWrapper<AppStore>(makeStore)
