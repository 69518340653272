import BaseRepository from './BaseRepository'

class UserRepository extends BaseRepository {
  url() {
    return '/users'
  }

  async profile(query = {}, headers = {}) {
    try {
      let response = await this.httpClient.get(this.url() + '/profile', {
        params: query,
        headers: headers,
      })
      return this.success(response.data)
    } catch (e) {
      return this.handlerHttpError(e)
    }
  }

  async userUpdate(data: any, query = {}, headers = {}) {
    try {
      let response = await this.httpClient.put(this.url(), data, {
        params: query,
        headers: headers,
      })
      return this.success(response.data)
    } catch (e) {
      return this.handlerHttpError(e)
    }
  }
}

export default UserRepository
