import BaseRepository from './BaseRepository'
class CartRepository extends BaseRepository {
  url() {
    return '/'
  }
  handlerHttpError(e: any) {
    if (e.response && e.response.data) {
      let errorMsg = e.response.data.msg
      return this.error(errorMsg)
    } else {
      throw e
    }
  }

  async cartCreate(data: any, query = {}, headers = {}) {
    try {
      let response = await this.httpClient.post(this.url() + 'carts', data, {
        params: query,
        headers: headers,
      })
      return this.success(response.data)
    } catch (e) {
      return this.handlerHttpError(e)
    }
  }

  async cartAdd(data: any, query = {}, headers = {}) {
    try {
      let response = await this.httpClient.post(this.url() + 'carts/add', data, {
        params: query,
        headers: headers,
      })
      return this.success(response.data)
    } catch (e) {
      return this.handlerHttpError(e)
    }
  }
  

  async cartList(query: any, headers = {}) {
    try {
      let response = await this.httpClient.get(this.url() + 'carts', {
        params: query,
        headers: headers,
      })
      return this.success(response.data)
    } catch (e) {
      return this.handlerHttpError(e)
    }
  }

  async cartUpdate(data: any, query = {}, headers = {}) {
    try {
      let response = await this.httpClient.post(this.url() + `carts/update`, data, {
        params: query,
        headers: headers,
      })
      return this.success(response.data)
    } catch (e) {
      return this.handlerHttpError(e)
    }
  }
  async cartDelete(data: any, query = {}, headers = {}) {
    try {
      let response = await this.httpClient.delete(this.url() + 'carts', {
        params: {
          store_id: data.store_id,
          table_id: data.table_id,
          cart_token: data.cart_token,
        },
        headers: headers,
      })
      return this.success(response.data)
    } catch (e) {
      return this.handlerHttpError(e)
    }
  }
  async cartEmpty(data: any) {
    try {
      let response = await this.httpClient.post(this.url() + 'carts/empty', data)
      return this.success(response.data)
    } catch (e) {
      return this.handlerHttpError(e)
    }
  }
}

export default CartRepository
