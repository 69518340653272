import { storeBySubdomain } from '@/features/store/store-slice'
import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import ProductRepository from '../../app/repositories/ProductRepository'
import { show } from '../notify/notify-slice'

const productRepo = new ProductRepository()

export const productCreate = createAsyncThunk(
  'products/create',
  async (data: any, { rejectWithValue, dispatch, getState }) => {
    let state: any = getState()
    data.store_id = data.store_id ? data.store_id : null
    if (state.store.stores.length && !data.store_id) {
      data.store_id = state.store.stores[0].ID
    }
    let { status, response } = await productRepo.productCreate(data)

    if (!status) {
      dispatch(
        show({
          content: response,
          severity: 'error',
        })
      )
      return rejectWithValue(response)
    }

    // dispatch(
    //   show({
    //     content: 'Store create success',
    //     severity: 'success',
    //   })
    // )
    return response
  }
)

export const productList = createAsyncThunk(
  'products/list',
  async (params: any, { rejectWithValue, dispatch, getState }) => {
    let state: any = getState()
    let newParams = { ...params }
    newParams.store_id = newParams.store_id ? newParams.store_id : null
    if (state.store.stores.length && !newParams.store_id) {
      newParams.store_id = state.store.stores[0].ID
    }

    let { status, response } = await productRepo.productList(newParams)

    if (!status) {
      dispatch(
        show({
          content: response,
          severity: 'error',
        })
      )
      return rejectWithValue(response)
    }

    return response
  }
)

export const productDetail = createAsyncThunk(
  'products',
  async (params: any, { rejectWithValue, dispatch, getState }) => {
    let state: any = getState()
    if (!params.store_id) {
      dispatch(storeBySubdomain({}))
    }
    params.store_id = params.store_id ? params.store_id : 2

    let { status, response } = await productRepo.productDetail(params)

    if (!status) {
      dispatch(
        show({
          content: response,
          severity: 'error',
        })
      )
      return rejectWithValue(response)
    }

    return response
  }
)

export const productDelete = createAsyncThunk(
  'products/delete',
  async ({ id, params }: any, { rejectWithValue, dispatch }) => {
    let { status, response } = await productRepo.delete(id, params)

    if (!status) {
      dispatch(
        show({
          content: response,
          severity: 'error',
        })
      )
      return rejectWithValue(response)
    }

    dispatch(
      show({
        content: 'Product delete success',
        severity: 'success',
      })
    )
    return response
  }
)

interface ProductState {
  isLoading: boolean
  product: any
  products: Array<object>
  productsPagination: any
  isCreated: boolean
  deleting: boolean
  productsFilters: any
}

const initialState: ProductState = {
  isLoading: false,
  product: null,
  products: [],
  productsPagination: {
    limit: 20,
    page: 1,
    total_pages: 1,
    total_rows: 1,
  },
  isCreated: false,
  deleting: false,
  productsFilters: {
    page: 1,
    limit: 20,
    store_id: 0,
    sort_by: 'id:desc',
  },
}

const appSlice = createSlice({
  name: 'app',
  initialState,
  reducers: {
    resetStateCreated(state) {
      state.isCreated = false
    },
    setProductsFilters(state, action: PayloadAction<any>) {
      state.productsFilters = action.payload
    },
  },
  extraReducers: (builder) => {
    builder.addCase(productCreate.pending, (state) => {
      state.isLoading = true
    })

    builder.addCase(productCreate.fulfilled, (state, action) => {
      state.isLoading = false
      state.product = action.payload.data
      state.isCreated = true
    })

    builder.addCase(productCreate.rejected, (state, action) => {
      state.isLoading = false
    })

    builder.addCase(productList.pending, (state) => {})

    builder.addCase(productList.fulfilled, (state, action) => {
      state.products = action.payload.data
      state.productsPagination = action.payload.pagination
    })

    builder.addCase(productList.rejected, (state, action) => {})

    builder.addCase(productDetail.pending, (state) => {
      state.isLoading = true
    })

    builder.addCase(productDetail.fulfilled, (state, action) => {
      state.isLoading = false
      state.product = action.payload.data
    })

    builder.addCase(productDetail.rejected, (state, action) => {
      state.isLoading = false
    })

    builder.addCase(productDelete.pending, (state) => {
      state.deleting = true
    })

    builder.addCase(productDelete.fulfilled, (state, action) => {
      state.deleting = false
    })

    builder.addCase(productDelete.rejected, (state, action) => {
      state.deleting = false
    })
  },
})

export const { resetStateCreated, setProductsFilters } = appSlice.actions
export default appSlice.reducer
