import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import ThemeRepository from '../../app/repositories/ThemeRepository'
import { show } from '../notify/notify-slice'

const themeRepo = new ThemeRepository()

export const themeMain = createAsyncThunk(
  'themes/main',
  async (params: any, { rejectWithValue, dispatch, getState }) => {
    let state: any = getState()
    params.store_id = params.store_id ? params.store_id : null
    if (state.store.stores.length && !params.store_id) {
      params.store_id = state.store.stores.ID
    }

    let { status, response } = await themeRepo.themeMain(params)

    if (!status) {
      dispatch(
        show({
          content: response,
          severity: 'error',
        })
      )
      return rejectWithValue(response)
    }

    return response
  }
)

export const themeCreate = createAsyncThunk(
  'themes/create',
  async (data: any, { rejectWithValue, dispatch, getState }) => {
    let state: any = getState()
    data.store_id = data.store_id ? data.store_id : null
    if (state.store.stores.length && !data.store_id) {
      data.store_id = state.store.stores[0].ID
    }

    let { status, response } = await themeRepo.themeCreate(data)

    // if (!status) {
    //   dispatch(
    //     show({
    //       content: response,
    //       severity: 'error',
    //     })
    //   )
    //   return rejectWithValue(response)
    // }

    // dispatch(
    //   show({
    //     content: 'Theme create success',
    //     severity: 'success',
    //   })
    // )
    // return response
  }
)

export const themeList = createAsyncThunk(
  'themes/list',
  async (params: any, { rejectWithValue, dispatch, getState }) => {
    let state: any = getState()
    params.store_id = params.store_id ? params.store_id : null
    if (state.store.stores.length && !params.store_id) {
      params.store_id = state.store.stores[0].ID
    }

    let { status, response } = await themeRepo.themeList(params)

    if (!status) {
      dispatch(
        show({
          content: response,
          severity: 'error',
        })
      )
      return rejectWithValue(response)
    }

    return response
  }
)

export const themeUpdate = createAsyncThunk(
  'themes/update',
  async ({ id, data }: { id: number; data: any }, { rejectWithValue, dispatch, getState }) => {
    let state: any = getState()
    data.store_id = data.store_id ? data.store_id : null
    if (state.store.stores.length && !data.store_id) {
      data.store_id = state.store.stores[0].ID
    }

    let { status, response } = await themeRepo.themeUpdate(id, data)

    if (!status) {
      dispatch(
        show({
          content: response,
          severity: 'error',
        })
      )
      return rejectWithValue(response)
    }

    dispatch(
      show({
        content: 'Theme update success',
        severity: 'success',
      })
    )

    return response
  }
)

interface AppState {
  isLoading: boolean
  themes: Array<object>
  theme: Object
  themesPagination: any
  isCreated: boolean
}

const initialState: AppState = {
  isLoading: false,
  theme: {},
  themes: [],
  themesPagination: null,
  isCreated: false,
}

const appSlice = createSlice({
  name: 'app',
  initialState,
  reducers: {
    resetStateCreated(state) {
      state.isCreated = false
    },
  },
  extraReducers: (builder) => {
    builder.addCase(themeMain.pending, (state) => {})

    builder.addCase(themeMain.fulfilled, (state, action) => {
      state.theme = action.payload.data
    })

    builder.addCase(themeMain.rejected, (state, action) => {})

    builder.addCase(themeCreate.pending, (state) => {
      state.isLoading = true
    })

    builder.addCase(themeCreate.fulfilled, (state, action) => {
      state.isLoading = false
      state.isCreated = true
    })

    builder.addCase(themeCreate.rejected, (state, action) => {
      state.isLoading = false
    })

    builder.addCase(themeList.pending, (state) => {})

    builder.addCase(themeList.fulfilled, (state, action) => {
      state.themes = action.payload.data
      state.themesPagination = action.payload.pagination
    })

    builder.addCase(themeList.rejected, (state, action) => {})

    builder.addCase(themeUpdate.pending, (state) => {
      state.isLoading = true
    })

    builder.addCase(themeUpdate.fulfilled, (state, action) => {
      state.isLoading = false
    })

    builder.addCase(themeUpdate.rejected, (state, action) => {
      state.isLoading = false
    })
  },
})

export const { resetStateCreated } = appSlice.actions
export default appSlice.reducer
