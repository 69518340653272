import BaseRepository from './BaseRepository'

class MenuRepository extends BaseRepository {
  url() {
    return '/menus'
  }

  handlerHttpError(e: any) {
    if (e.response && e.response.data) {
      let errorMsg = e.response.data.msg
      return this.error(errorMsg)
    } else {
      throw e
    }
  }

  async menuCreate(data: any, query = {}, headers = {}) {
    try {
      let response = await this.httpClient.post(this.url(), data, {
        params: query,
        headers: headers,
      })
      return this.success(response.data)
    } catch (e) {
      return this.handlerHttpError(e)
    }
  }

  async menuList(query: any, headers = {}) {
    try {
      let response = await this.httpClient.get(this.url(), {
        params: query,
        headers: headers,
      })
      return this.success(response.data)
    } catch (e) {
      return this.handlerHttpError(e)
    }
  }
}

export default MenuRepository
