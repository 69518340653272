import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import TableRepository from '@/app/repositories/TableRepository'
import { show } from '../notify/notify-slice'

const tableRepo = new TableRepository()

export const tableCreate = createAsyncThunk(
  'table/create',
  async (data: any, { rejectWithValue, dispatch }) => {
    let { status, response } = await tableRepo.create(data)

    if (!status) {
      dispatch(
        show({
          content: response,
          severity: 'error',
        })
      )
      return rejectWithValue(response)
    }

    dispatch(
      show({
        content: 'Table create success',
        severity: 'success',
      })
    )
    return response
  }
)

export const tableList = createAsyncThunk(
  'table/list',
  async (params: any, { rejectWithValue, dispatch }) => {
    let { status, response } = await tableRepo.list(params)

    if (!status) {
      dispatch(
        show({
          content: response,
          severity: 'error',
        })
      )
      return rejectWithValue(response)
    }

    return response
  }
)

export const tableDelete = createAsyncThunk(
  'table/delete',
  async ({ id, params }: { id: number; params: any }, { rejectWithValue, dispatch }) => {
    let { status, response } = await tableRepo.delete(id, params)

    if (!status) {
      dispatch(
        show({
          content: response,
          severity: 'error',
        })
      )
      return rejectWithValue(response)
    }

    dispatch(
      show({
        content: 'Table delete success',
        severity: 'success',
      })
    )

    return response
  }
)

interface TableState {
  isLoading: boolean
  deleteSubmitting: boolean
  tables: any
  tablesPagination: any
}

const initialState: TableState = {
  isLoading: false,
  deleteSubmitting: false,
  tables: [],
  tablesPagination: {
    limit: 20,
    page: 1,
    total_pages: 1,
    total_rows: 4,
  },
}

const tableSlice = createSlice({
  name: 'table',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(tableCreate.pending, (state) => {
      state.isLoading = true
    })

    builder.addCase(tableCreate.fulfilled, (state, action) => {
      state.isLoading = false
    })

    builder.addCase(tableCreate.rejected, (state, action) => {
      state.isLoading = false
    })

    builder.addCase(tableList.pending, (state) => {})

    builder.addCase(tableList.fulfilled, (state, action) => {
      state.tables = action.payload.data
      state.tablesPagination = action.payload.pagination
    })

    builder.addCase(tableList.rejected, (state, action) => {})

    builder.addCase(tableDelete.pending, (state) => {
      state.deleteSubmitting = true
    })

    builder.addCase(tableDelete.fulfilled, (state, action) => {
      state.deleteSubmitting = false
    })

    builder.addCase(tableDelete.rejected, (state, action) => {
      state.deleteSubmitting = false
    })
  },
})

export const {} = tableSlice.actions
export default tableSlice.reducer
