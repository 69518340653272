import BaseRepository from './BaseRepository'

class SyncDataRepository extends BaseRepository {
  url() {
    return ''
  }

  async sapoProductsSync(data = {}, query = {}, headers = {}) {
    try {
      let response = await this.httpClient.post(this.url() + '/sapo/products/sync', data, {
        params: query,
        headers: headers,
      })
      return this.success(response.data)
    } catch (e) {
      return this.handlerHttpError(e)
    }
  }

  async sapoCustomCollectionsSync(data = {}, query = {}, headers = {}) {
    try {
      let response = await this.httpClient.post(
        this.url() + '/sapo/custom-collections/sync',
        data,
        {
          params: query,
          headers: headers,
        }
      )
      return this.success(response.data)
    } catch (e) {
      return this.handlerHttpError(e)
    }
  }

  async sapoSmartCollectionsSync(data = {}, query = {}, headers = {}) {
    try {
      let response = await this.httpClient.post(this.url() + '/sapo/smart-collections/sync', data, {
        params: query,
        headers: headers,
      })
      return this.success(response.data)
    } catch (e) {
      return this.handlerHttpError(e)
    }
  }

  async kiotvietProductsSync(data = {}, query = {}, headers = {}) {
    try {
      let response = await this.httpClient.post(this.url() + '/kiotviet/products/sync', data, {
        params: query,
        headers: headers,
      })
      return this.success(response.data)
    } catch (e) {
      return this.handlerHttpError(e)
    }
  }

  async kiotvietCollectionsSync(data = {}, query = {}, headers = {}) {
    try {
      let response = await this.httpClient.post(this.url() + '/kiotviet/collections/sync', data, {
        params: query,
        headers: headers,
      })
      return this.success(response.data)
    } catch (e) {
      return this.handlerHttpError(e)
    }
  }
}

export default SyncDataRepository
