import BaseRepository from './BaseRepository'
class NotificationRepository extends BaseRepository {
  url() {
    return '/'
  }
  handlerHttpError(e: any) {
    if (e.response && e.response.data) {
      let errorMsg = e.response.data.msg
      return this.error(errorMsg)
    } else {
      throw e
    }
  }

  async notificationCreate(data: any, query = {}, headers = {}) {
    try {
      let response = await this.httpClient.post(this.url() + 'notifications', data, {
        params: query,
        headers: headers,
      })
      return this.success(response.data)
    } catch (e) {
      return this.handlerHttpError(e)
    }
  }

  async notificationAdd(data: any, query = {}, headers = {}) {
    try {
      let response = await this.httpClient.post(this.url() + 'notifications/add', data, {
        params: query,
        headers: headers,
      })
      return this.success(response.data)
    } catch (e) {
      return this.handlerHttpError(e)
    }
  }
  

  async notificationList(query: any, headers = {}) {
    try {
      let response = await this.httpClient.get(this.url() + 'notifications', {
        params: query,
        headers: headers,
      })
      return this.success(response.data)
    } catch (e) {
      return this.handlerHttpError(e)
    }
  }

  async notificationUpdate(data: any, query = {}, headers = {}) {
    try {
      let response = await this.httpClient.post(this.url() + `notifications/update`, data, {
        params: query,
        headers: headers,
      })
      return this.success(response.data)
    } catch (e) {
      return this.handlerHttpError(e)
    }
  }
  async notificationDelete(data: any, query = {}, headers = {}) {
    try {
      let response = await this.httpClient.delete(this.url() + 'notifications', {
        params: {
          store_id: data.store_id,
          table_id: data.table_id,
          notification_token: data.notification_token,
        },
        headers: headers,
      })
      return this.success(response.data)
    } catch (e) {
      return this.handlerHttpError(e)
    }
  }
  async notificationEmpty(data: any) {
    try {
      let response = await this.httpClient.post(this.url() + 'notifications/empty', data)
      return this.success(response.data)
    } catch (e) {
      return this.handlerHttpError(e)
    }
  }
}

export default NotificationRepository
