import {
  ActionReducerMapBuilder,
  createAsyncThunk,
  createSlice,
  PayloadAction,
} from '@reduxjs/toolkit'
import CollectRepository from '@/app/repositories/CollectRepository'
import { show } from '@/features/notify/notify-slice'

const collectRepo = new CollectRepository()

export const collectCreateBatch = createAsyncThunk(
  'manager/collects/create/batch',
  async (data: any, { rejectWithValue, dispatch }) => {
    let { status, response } = await collectRepo.createBatch(data)

    if (!status) {
      dispatch(
        show({
          content: response,
          severity: 'error',
        })
      )
      return rejectWithValue(response)
    }

    return response
  }
)

export const collectDeleteBatch = createAsyncThunk(
  'manager/collects/delete/batch',
  async (data: any, { rejectWithValue, dispatch }) => {
    let { status, response } = await collectRepo.deleteBatch(data)

    if (!status) {
      dispatch(
        show({
          content: response,
          severity: 'error',
        })
      )
      return rejectWithValue(response)
    }

    return response
  }
)

export const getCollectProduct = createAsyncThunk(
  'manager/collects/products/detail',
  async (productId: any, { rejectWithValue, dispatch }) => {
    let { status, response } = await collectRepo.getByProductId(productId)

    if (!status) {
      dispatch(
        show({
          content: response,
          severity: 'error',
        })
      )
      return rejectWithValue(response)
    }

    return response
  }
)

export const updateCollectProduct = createAsyncThunk(
  'manager/collects/products/update',
  async ({ productId, data }: { productId: any; data: any }, { rejectWithValue, dispatch }) => {
    let { status, response } = await collectRepo.updateByProductId(productId, data)

    if (!status) {
      dispatch(
        show({
          content: response,
          severity: 'error',
        })
      )
      return rejectWithValue(response)
    }

    return response
  }
)

interface ManagerCollectState {}

const initialState: ManagerCollectState = {}

const managerCollectSlice = createSlice({
  name: 'managerCollect',
  initialState,
  reducers: {},
  extraReducers: (builder: ActionReducerMapBuilder<ManagerCollectState>) => {},
})

export const {} = managerCollectSlice.actions
export default managerCollectSlice.reducer
