import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import StoreRepository from '../../app/repositories/StoreRepository'
import { show } from '../notify/notify-slice'

const storeRepo = new StoreRepository()

export const storeBySubdomain = createAsyncThunk(
  'stores/subDomain',
  async (data: any, { rejectWithValue, dispatch, getState }) => {
    let state: any = getState()
    let { status, response } = await storeRepo.storeBySubdomain(data)
    if (!status) {
      dispatch(
        show({
          content: response,
          severity: 'error',
        })
      )
      return rejectWithValue(response)
    }
    return response
  }
)

export const storeCreate = createAsyncThunk(
  'stores',
  async (data: any, { rejectWithValue, dispatch, getState }) => {
    let state: any = getState()
    let { status, response } = await storeRepo.storeCreate(data)

    if (!status) {
      dispatch(
        show({
          content: response,
          severity: 'error',
        })
      )
      return rejectWithValue(response)
    }

    return response
  }
)

export const storeList = createAsyncThunk(
  'stores/list',
  async (params: any, { rejectWithValue, dispatch, getState }) => {
    let state: any = getState()

    let { status, response } = await storeRepo.storeList(params)

    if (!status) {
      dispatch(
        show({
          content: response,
          severity: 'error',
        })
      )
      return rejectWithValue(response)
    }

    return response
  }
)

export const storeUpdate = createAsyncThunk(
  'stores/update',
  async ({ id, data }: { id: number; data: any }, { rejectWithValue, dispatch, getState }) => {
    let state: any = getState()
    let { status, response } = await storeRepo.storeUpdate(id, data)

    if (!status) {
      dispatch(
        show({
          content: response,
          severity: 'error',
        })
      )
      return rejectWithValue(response)
    }

    dispatch(
      show({
        content: 'Store update success',
        severity: 'success',
      })
    )
    return response
  }
)

interface AppState {
  isLoading: boolean
  isLoadingList: boolean
  store: any
  stores: Array<object>
  storesPagination: any
  isCreated: boolean
  minutes: number
  seconds: number
}

const initialState: AppState = {
  isLoading: false,
  isLoadingList: false,
  store: null,
  stores: [],
  storesPagination: null,
  isCreated: false,
  minutes: 2,
  seconds: 0,
}

const appSlice = createSlice({
  name: 'app',
  initialState,
  reducers: {
    resetStateCreated(state: any) {
      state.isCreated = false
    },
    setMinutes(state, action: PayloadAction<any>) {
        state.minutes = action.payload
    },
    setSeconds(state, action: PayloadAction<any>) {
        state.seconds = action.payload
    },
  },
  extraReducers: (builder: any) => {
    builder.addCase(storeBySubdomain.pending, (state: any) => {
      state.isLoading = true
    })

    builder.addCase(storeBySubdomain.fulfilled, (state: any, action: any) => {
      state.isLoading = false
      state.store = action.payload.data
    })

    builder.addCase(storeBySubdomain.rejected, (state: any, action: any) => {
      state.isLoading = false
    })
    builder.addCase(storeCreate.pending, (state: any) => {
      state.isLoading = true
    })

    builder.addCase(storeCreate.fulfilled, (state: any, action: any) => {
      state.isLoading = false
      state.store = action.payload.data
      state.isCreated = true
    })

    builder.addCase(storeCreate.rejected, (state: any, action: any) => {
      state.isLoading = false
    })

    builder.addCase(storeList.pending, (state: any) => {
      state.isLoadingList = true
    })

    builder.addCase(storeList.fulfilled, (state: any, action: any) => {
      state.stores = action.payload.data
      state.storesPagination = action.payload.pagination
      state.isLoadingList = false
    })

    builder.addCase(storeList.rejected, (state: any, action: any) => {
      state.isLoadingList = false
    })

    builder.addCase(storeUpdate.pending, (state: any) => {
      state.isLoading = true
    })

    builder.addCase(storeUpdate.fulfilled, (state: any, action: any) => {
      state.isLoading = false
    })

    builder.addCase(storeUpdate.rejected, (state: any, action: any) => {
      state.isLoading = false
    })
  },
})

export const { resetStateCreated, setSeconds, setMinutes } = appSlice.actions
export default appSlice.reducer
