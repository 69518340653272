import BaseRepository from './BaseRepository'

class ThemeRepository extends BaseRepository {
  url() {
    return '/themes'
  }

  handlerHttpError(e: any) {
    if (e.response && e.response.data) {
      let errorMsg = e.response.data.msg
      return this.error(errorMsg)
    } else {
      throw e
    }
  }
  async themeMain(query: any, headers = {}) {
    try {
      let response = await this.httpClient.get(this.url() + '/main', {
        params: query,
        headers: headers,
      })
      return this.success(response.data)
    } catch (e) {
      return this.handlerHttpError(e)
    }
  }

  async themeCreate(data: any, query = {}, headers = {}) {
    let formData = new FormData()
    formData.append('images', data.cover_image)
    formData.append('store_id', data.store_id)
    formData.append('colors', JSON.stringify(data.colors))
    formData.append('role', data.role)

    headers = {
      Authorization: 'Bearer ' + data.token,
      'Content-Type': 'multipart/form-data',
    }
    try {
      let response = await this.httpClient.post(this.url(), formData, {
        params: query,
        headers: headers,
      })
      return this.success(response.data)
    } catch (e) {
      return this.handlerHttpError(e)
    }
  }

  async themeList(query: any, headers = {}) {
    try {
      let response = await this.httpClient.get(this.url(), {
        params: query,
        headers: headers,
      })
      return this.success(response.data)
    } catch (e) {
      return this.handlerHttpError(e)
    }
  }

  async themeUpdate(id: number, data: any, query = {}, headers = {}) {
    let formData = new FormData()
    formData.append('images', data.cover_image)
    formData.append('store_id', data.store_id)
    formData.append('colors', JSON.stringify(data.colors))
    formData.append('role', data.role)

    headers = {
      Authorization: 'Bearer ' + data.token,
      'Content-Type': 'multipart/form-data',
    }
    try {
      let response = await this.httpClient.put(this.url() + `/${id}`, formData, {
        params: query,
        headers: headers,
      })
      return this.success(response.data)
    } catch (e) {
      return this.handlerHttpError(e)
    }
  }
}

export default ThemeRepository
