import BaseRepository from './BaseRepository'

class CollectRepository extends BaseRepository {
  url() {
    return '/collects'
  }

  async createBatch(data: any, query = {}, headers = {}) {
    try {
      let response = await this.httpClient.post(this.url() + '/batch', data, {
        params: query,
        headers: headers,
      })
      return this.success(response.data)
    } catch (e) {
      return this.handlerHttpError(e)
    }
  }

  async deleteBatch(data: any, query = {}, headers = {}) {
    try {
      let response = await this.httpClient.delete(this.url() + '/batch', {
        data: data,
        params: query,
        headers: headers,
      })
      return this.success(response.data)
    } catch (e) {
      return this.handlerHttpError(e)
    }
  }

  async getByProductId(productId: any, query = {}, headers = {}) {
    try {
      let response = await this.httpClient.get(this.url() + `/products/${productId}`, {
        params: query,
        headers: headers,
      })
      return this.success(response.data)
    } catch (e) {
      return this.handlerHttpError(e)
    }
  }

  async updateByProductId(productId: number, data = {}, query = {}, headers = {}) {
    try {
      let response = await this.httpClient.put(this.url() + `/products/${productId}`, data, {
        params: query,
        headers: headers,
      })
      return this.success(response.data)
    } catch (e) {
      return this.handlerHttpError(e)
    }
  }
}

export default CollectRepository
