import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import TableRepository from '@/app/repositories/TableRepository'
import { show } from '@/features/notify/notify-slice'

const tableRepo = new TableRepository()

export const tableCreate = createAsyncThunk(
  'tables/create',
  async (data: any, { rejectWithValue, dispatch }) => {
    let { status, response } = await tableRepo.create(data)

    if (!status) {
      dispatch(
        show({
          content: response,
          severity: 'error',
        })
      )
      return rejectWithValue(response)
    }

    dispatch(
      show({
        content: 'Table create success',
        severity: 'success',
      })
    )
    return response
  }
)

export const tableList = createAsyncThunk(
  'tables/list',
  async (params: any, { rejectWithValue, dispatch, getState }) => {
    let state: any = getState()
    let newParams = { ...params }
    if (state.managerStore.store) {
      newParams.store_id = state.managerStore.store.ID
    }

    let { status, response } = await tableRepo.list(newParams)

    if (!status) {
      dispatch(
        show({
          content: response,
          severity: 'error',
        })
      )
      return rejectWithValue(response)
    }

    return response
  }
)

export const tableDelete = createAsyncThunk(
  'tables/delete',
  async ({ id, params }: { id: number; params: any }, { rejectWithValue, dispatch, getState }) => {
    let state: any = getState()
    let newParams = { ...params }
    if (state.managerStore.store) {
      newParams.store_id = state.managerStore.store.ID
    }
    let { status, response } = await tableRepo.delete(id, newParams)

    if (!status) {
      dispatch(
        show({
          content: response,
          severity: 'error',
        })
      )
      return rejectWithValue(response)
    }

    dispatch(
      show({
        content: 'Table delete success',
        severity: 'success',
      })
    )

    return response
  }
)

export const tableDetail = createAsyncThunk(
  'tables/detail',
  async ({ id, params }: { id: string; params: any }, { rejectWithValue, dispatch, getState }) => {
    let state: any = getState()
    let newParams = { ...params }
    if (state.managerStore.store) {
      newParams.store_id = state.managerStore.store.ID
    }
    let { status, response } = await tableRepo.detail(id, newParams)

    if (!status) {
      dispatch(
        show({
          content: response,
          severity: 'error',
        })
      )
      return rejectWithValue(response)
    }

    return response
  }
)

export const tableUpdate = createAsyncThunk(
  'tables/update',
  async ({ id, data }: { id: string; data: any }, { rejectWithValue, dispatch }) => {
    let { status, response } = await tableRepo.update(id, data)

    if (!status) {
      dispatch(
        show({
          content: response,
          severity: 'error',
        })
      )
      return rejectWithValue(response)
    }

    dispatch(
      show({
        content: 'Table update success',
        severity: 'success',
      })
    )

    return response
  }
)

export const tableBulkDelete = createAsyncThunk(
  'tables/bulk-delete',
  async (data: any, { rejectWithValue, dispatch, getState }) => {
    let state: any = getState()
    if (state.managerStore.store) {
      data.store_id = state.managerStore.store.ID
    }

    let { status, response } = await tableRepo.bulkDelete(data)

    if (!status) {
      dispatch(
        show({
          content: response,
          severity: 'error',
        })
      )
      return rejectWithValue(response)
    }

    dispatch(
      show({
        content: 'Table bulk delete success',
        severity: 'success',
      })
    )

    return response
  }
)

interface TableState {
  isLoading: boolean
  deleteSubmitting: boolean
  tablesLoading: any
  tables: any
  tablesPagination: any
  tablesFilters: any
}

const initialState: TableState = {
  isLoading: false,
  deleteSubmitting: false,
  tablesLoading: false,
  tables: [],
  tablesPagination: {
    limit: 20,
    page: 1,
    total_pages: 1,
    total_rows: 1,
  },
  tablesFilters: {
    page: 1,
    limit: 20,
    store_id: 0,
    name: '',
  },
}

const managerTableSlice = createSlice({
  name: 'managerTable',
  initialState,
  reducers: {
    setTablesFilters(state, action: PayloadAction<any>) {
      state.tablesFilters = action.payload
    },
  },
  extraReducers: (builder) => {
    builder.addCase(tableCreate.pending, (state) => {
      state.isLoading = true
    })

    builder.addCase(tableCreate.fulfilled, (state, action) => {
      state.isLoading = false
    })

    builder.addCase(tableCreate.rejected, (state, action) => {
      state.isLoading = false
    })

    builder.addCase(tableList.pending, (state) => {
      state.tablesLoading = true
    })

    builder.addCase(tableList.fulfilled, (state, action) => {
      state.tables = action.payload.data
      state.tablesPagination = action.payload.pagination
      state.tablesLoading = false
    })

    builder.addCase(tableList.rejected, (state, action) => {
      state.tablesLoading = false
    })

    builder.addCase(tableDelete.pending, (state) => {
      state.deleteSubmitting = true
    })

    builder.addCase(tableDelete.fulfilled, (state, action) => {
      state.deleteSubmitting = false
    })

    builder.addCase(tableDelete.rejected, (state, action) => {
      state.deleteSubmitting = false
    })
  },
})

export const { setTablesFilters } = managerTableSlice.actions
export default managerTableSlice.reducer
