import {
  ActionReducerMapBuilder,
  createAsyncThunk,
  createSlice,
  PayloadAction,
} from '@reduxjs/toolkit'
import ProductRepository from '@/app/repositories/ProductRepository'
import { show } from '@/features/notify/notify-slice'

const productRepo = new ProductRepository()

export const productCreate = createAsyncThunk(
  'products/create',
  async (data: any, { rejectWithValue, dispatch }) => {
    let { status, response } = await productRepo.productCreate(data)

    if (!status) {
      dispatch(
        show({
          content: response,
          severity: 'error',
        })
      )
      return rejectWithValue(response)
    }

    dispatch(
      show({
        content: 'Product create success',
        severity: 'success',
      })
    )
    return response
  }
)

export const productList = createAsyncThunk(
  'products/list',
  async (params: any, { rejectWithValue, dispatch, getState }) => {
    let state: any = getState()
    let newParams = { ...params }
    if (state.managerStore.store) {
      newParams.store_id = state.managerStore.store.ID
    }

    let { status, response } = await productRepo.list(newParams)

    if (!status) {
      dispatch(
        show({
          content: response,
          severity: 'error',
        })
      )
      return rejectWithValue(response)
    }

    return response
  }
)

export const productDelete = createAsyncThunk(
  'products/delete',
  async ({ id, params }: { id: number; params: any }, { rejectWithValue, dispatch, getState }) => {
    let state: any = getState()
    let newParams = { ...params }
    if (state.managerStore.store) {
      newParams.store_id = state.managerStore.store.ID
    }
    let { status, response } = await productRepo.delete(id, newParams)

    if (!status) {
      dispatch(
        show({
          content: response,
          severity: 'error',
        })
      )
      return rejectWithValue(response)
    }

    dispatch(
      show({
        content: 'Product delete success',
        severity: 'success',
      })
    )

    return response
  }
)

export const productDetail = createAsyncThunk(
  'products/detail',
  async ({ id, params }: { id: string; params: any }, { rejectWithValue, dispatch, getState }) => {
    let state: any = getState()
    let newParams = { ...params }
    if (state.managerStore.store) {
      newParams.store_id = state.managerStore.store.ID
    }
    let { status, response } = await productRepo.detail(id, newParams)

    if (!status) {
      dispatch(
        show({
          content: response,
          severity: 'error',
        })
      )
      return rejectWithValue(response)
    }

    return response
  }
)

export const productUpdate = createAsyncThunk(
  'products/update',
  async ({ id, data }: { id: string; data: any }, { rejectWithValue, dispatch }) => {
    let { status, response } = await productRepo.productUpdate(id, data)

    if (!status) {
      dispatch(
        show({
          content: response,
          severity: 'error',
        })
      )
      return rejectWithValue(response)
    }

    dispatch(
      show({
        content: 'Product update success',
        severity: 'success',
      })
    )

    return response
  }
)

export const productListInput = createAsyncThunk(
  'manager/products/list/input',
  async (params: any, { rejectWithValue, dispatch, getState }) => {
    let state: any = getState()
    let newParams = { ...params }
    if (state.managerStore.store) {
      newParams.store_id = state.managerStore.store.ID
    }

    let { status, response } = await productRepo.list(newParams)

    if (!status) {
      dispatch(
        show({
          content: response,
          severity: 'error',
        })
      )
      return rejectWithValue(response)
    }

    return response
  }
)

interface ManagerProductState {
  isLoading: boolean
  deleteSubmitting: boolean
  productsLoading: any
  products: any
  productsPagination: any
  productsFilters: any
  productsInputLoading: boolean
  productsInput: any
  productsInputPagination: any
  productsInputFilters: any
}

const initialState: ManagerProductState = {
  isLoading: false,
  deleteSubmitting: false,
  productsLoading: false,
  products: [],
  productsPagination: {
    limit: 20,
    page: 1,
    total_pages: 1,
    total_rows: 1,
  },
  productsFilters: {
    page: 1,
    limit: 20,
    sort_by: 'id:desc',
    store_id: 0,
    name: '',
    status: '',
  },
  productsInputLoading: false,
  productsInput: [],
  productsInputPagination: {
    limit: 20,
    page: 1,
    total_pages: 1,
    total_rows: 1,
  },
  productsInputFilters: {
    page: 1,
    limit: 20,
    sort_by: 'id:desc',
    store_id: 0,
    name: '',
    status: '',
  },
}

const managerProductSlice = createSlice({
  name: 'managerProduct',
  initialState,
  reducers: {
    setProductsFilters(state, action: PayloadAction<any>) {
      state.productsFilters = action.payload
    },
    setProductsInputFilters(state, action: PayloadAction<any>) {
      state.productsInputFilters = action.payload
    },
  },
  extraReducers: (builder: ActionReducerMapBuilder<ManagerProductState>) => {
    builder.addCase(productCreate.pending, (state: ManagerProductState) => {
      state.isLoading = true
    })

    builder.addCase(productCreate.fulfilled, (state: ManagerProductState) => {
      state.isLoading = false
    })

    builder.addCase(productCreate.rejected, (state: ManagerProductState) => {
      state.isLoading = false
    })

    builder.addCase(productList.pending, (state: ManagerProductState) => {
      state.productsLoading = true
    })

    builder.addCase(
      productList.fulfilled,
      (state: ManagerProductState, action: PayloadAction<any>) => {
        state.products = action.payload.data
        state.productsPagination = action.payload.pagination
        state.productsLoading = false
      }
    )

    builder.addCase(productList.rejected, (state: ManagerProductState) => {
      state.productsLoading = false
    })

    builder.addCase(productDelete.pending, (state: ManagerProductState) => {
      state.deleteSubmitting = true
    })

    builder.addCase(productDelete.fulfilled, (state: ManagerProductState) => {
      state.deleteSubmitting = false
    })

    builder.addCase(productDelete.rejected, (state: ManagerProductState) => {
      state.deleteSubmitting = false
    })

    builder.addCase(productListInput.pending, (state: ManagerProductState) => {
      state.productsInputLoading = true
    })

    builder.addCase(
      productListInput.fulfilled,
      (state: ManagerProductState, action: PayloadAction<any>) => {
        state.productsInput = action.payload.data
        state.productsInputPagination = action.payload.pagination
        state.productsInputLoading = false
      }
    )

    builder.addCase(productListInput.rejected, (state: ManagerProductState) => {
      state.productsInputLoading = false
    })
  },
})

export const { setProductsFilters, setProductsInputFilters } = managerProductSlice.actions
export default managerProductSlice.reducer
