import BaseRepository from './BaseRepository'

class AppRepository extends BaseRepository {
  url() {
    return '/apps'
  }

  handlerHttpError(e: any) {
    if (e.response && e.response.data) {
      let errorMsg = e.response.data.msg
      return this.error(errorMsg)
    } else {
      throw e
    }
  }

  async kiotvietConnect(data: any, query = {}, headers = {}) {
    try {
      let response = await this.httpClient.post('/kiotviet/create-user', data, {
        params: query,
        headers: headers,
      })
      return this.success(response.data)
    } catch (e) {
      return this.handlerHttpError(e)
    }
  }

  async sapoConnect(query = {}, headers = {}) {
    try {
      let response = await this.httpClient.get('/sapo/get-auth-url', {
        params: query,
        headers: headers,
      })
      return this.success(response.data)
    } catch (e) {
      return this.handlerHttpError(e)
    }
  }
}

export default AppRepository
