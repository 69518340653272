import BaseRepository from './BaseRepository'

class TableRepository extends BaseRepository {
  url() {
    return '/tables'
  }

  async bulkDelete(data: any, query = {}, headers = {}) {
    try {
      let response = await this.httpClient.post(this.url() + '/bulk-delete', data, {
        params: query,
        headers: headers,
      })
      return this.success(response.data)
    } catch (e) {
      return this.handlerHttpError(e)
    }
  }
}

export default TableRepository
